// base
import { PuzImgGallery, PuzTxtImgTxt, PuzSteckbrief, UpperSpace, PuzImgTxt} from "./allserv";
import img1 from "./../../img/3fGarten-undLandschaftspflege/Pflege/Pflege_10.jpg"
import img2 from "./../../img/3fGarten-undLandschaftspflege/Baumfällarbeiten/Baumfällarbeiten_20.JPG"
import img3 from "./../../img/3fGarten-undLandschaftspflege/Landschaftspflege/Landschaftspflege_20.jpg"
import img4 from "./../../img/3fGarten-undLandschaftspflege/AEBI_TT240_10.jpg"
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images = importAllImages(require.context("./../../img/3fGarten-undLandschaftspflege", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const txt1 =
"Damit Ihr Garten oder Ihre Außenanlage so schön bleibt oder noch schöner wird, benötigt er die richtige Pflege und Aufmerksamkeit. "+
"Intensive und auf Ihren Garten abgestimmte Pflege kostet allerdings viel Zeit und Mühe und muss regelmäßig durchgeführt werden. " + 
"Jeder Garten ist einzigartig und hat andere Bedürfnisse. "+ 
"Deshalb beraten wir Sie gern ausführlich zu unseren Leistungen im Bereich Garten- und Landschaftspflege und übernehmen auf Wunsch die gesamte Pflege Ihres Gartens, "+ 
"damit dieser das ganze Jahr über für Sie ein schöner Entspannungsort und Wohlfühloase bleibt."

const leistungen1 = [
    "Düngen der Rasenflächen 1x im Frühjahr als Starterdüngung und 1x im Herbst",
    "Rabatten und Beete auflockern, Unkraut und unerwünschte Bestandteile entfernen",
    "Rabatten und Beete mit Dauerdünger mineralisch und organisch 2x jährlich versorgen und einarbeiten",
    "Stauden, Kleinsträucher, Bodendecker und Rosen jährlich kontrollieren, schneiden und gärtnerisch versorgen",
    "Sichtschutz- und Formhecken, Formgehölze schneiden",
    "Wintergehölzschnitt von Bäumen, Obstbäumen und Sträuchern entsprechend der Wuchs- und Habituskriterien der jeweiligen Pflanze durchführen",
    "Rasenpflege: Wässern, Mähen, Vertikutieren",
    "Bekämpfung von Schädlingsbefall, Pflanzenschutzmaßnahmen",
    "Fachgerechte Grünentsorgung",
    "Pflegebesuche und die Ausführung der jeweiligen Arbeiten erfolgen hier nach Voranmeldung entsprechend der jahreszeitlichen Randbedingungen und Erfordernissen sowie der Anzahl der jeweiligen Arbeitsgänge"
]

const txt2 = 
"Ein fachgerechter Rückschnitt ist ein wichtiger Bestandteil der Baumgesundheit. "+ 
"Wir kümmern uns mit unserem Fachwissen darum, dass Ihre Bäume rundum gepflegt, sicher und Ihre Obstbäume ertragreich sind. "+
"Soll ein Baum fachgerecht gefällt oder der Wurzelstock entfernt werden, sind wir ebenfalls Ihr sachkundiger Partner."

const txt3 =
"Wir führen alle unsere Arbeiten nach der neuesten Fassung der ZTV-Baumpflege durch:<br>"+
"- Baumfällung<br>"+
"- Baumpflege: Kronenkappung, Auslichtungsarbeiten, Totholzentfernung<br>"+
"- Sicherungsmaßnahmen, Düngen von Bäumen<br>"+
"- Wurzelstockfräsen, Wurzelstockentfernung, Rodung"

const txt4 =
"„Ziele des Naturschutzes und der Landschaftspflege sind es, die Natur und die Landschaft "+
"auf Grund ihres eigenen Wertes und als Grundlage für Leben und Gesundheit des Menschen "+
"auch in Verantwortung für die künftigen Generationen im besiedelten und unbesiedelten "+
"Bereich so zu schützen, dass die biologische Vielfalt, die Leistungs- und Funktionsfähigkeit "+
"des Naturhaushalts einschließlich der Regenerationsfähigkeit und nachhaltigen "+
"Nutzungsfähigkeit der Naturgüter sowie die Vielfalt, Eigenart und Schönheit sowie der "+
"Erholungswert von Natur und Landschaft auf Dauer gesichert sind; der Schutz umfasst auch "+
"die Pflege, die Entwicklung und, soweit erforderlich, die Wiederherstellung von Natur und "+
"Landschaft, wobei Freiräume im besiedelten und siedlungsnahen Bereich einschließlich ihrer "+
"Bestandteile, wie Parkanlagen, großflächige Grünanlagen und Grünzüge, Wälder und "+
"Waldränder, Bäume und Gehölzstrukturen, ... sowie gartenbau- und landwirtschaftlich "+
"genutzte Flächen, zu erhalten oder neu zu schaffen sind“ (§ 1 BNatSchG)."

const txt5 =
"Zur Pflege der Landschaft stehen uns verschiedene Maschinen und Geräte zur Verfügung:<br> "+
"Der AEBI TT 240 mit verschiedenen Anbaugeräten ist in der Grünflächen- und "+
"Landschaftspflege sowie in der Landwirtschaft einsetzbar: <br><br>" +
"- Gras- und Heuernte, einschließlich Abtransport <br>" +
"- Pflege von steilen Böschungen mit dem Schlägelmäher mit Mulchfunktion <br>" +
"- Mähen des Bewuchses auf empfindlichen Böden in Landschaftsschutzgebieten; zur "+
"Pflege von Trocken- und Magerrasenflächen sowie von Nass- und Feuchtwiesen, um " +
"die auf diesen Flächen vorhandenen, wertvollen Tier- und Pflanzengesellschaften (z.B. "+
"Orchideen) zu erhalten und zu schützen"




// site Erdarbeiten und Entwässerung
export default function GL () {
    return(
        <>
            <UpperSpace />
            <PuzImgTxt heading={"Pflege Privatgarten, öffentlicher Plätze, Firmengelände"} text={txt1} img={img1}/>
            <UpperSpace />
            <PuzSteckbrief leistungen={leistungen1}/>
            <UpperSpace />
            <PuzTxtImgTxt heading={"Baumfällung und Baumpflege"} text1={txt2} text2={txt3} img={img2}/>
            <UpperSpace />
            <PuzImgTxt heading={"Landschaftspflege"} text={txt4} img={img3}/>
            <PuzImgTxt heading={"AEBI TT 240"} text={txt5} img={img4} sub/>
            <UpperSpace />
            <PuzImgGallery imgs={images}/>
            <UpperSpace />
        </>
        
    );
}