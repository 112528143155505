// css:
import './../css/jobs.css';

// images:
import img1 from './../img/Jobs/img_00.jpg';
import img2 from './../img/Jobs/img_02.jpg';

// others:
import { PuzImgTxt, UpperSpace} from './allserv/allserv';
import { useWindowWidth } from '../../App';

const txt1 = 'Möchten Sie Teil eines dynamischen und kreativen Teams werden, ' +
'das täglich die Schönheit und Funktionalität von Außenanlagen neu gestaltet? Dann sind Sie bei uns genau richtig! </br>' +
'Die Stephan Goldmann GmbH ist ein führendes Unternehmen in der Region, das sich auf die Planung, ' +
'Gestaltung und Pflege von Gärten, Parks und öffentlichen Grünanlagen spezialisiert hat. </br>' +
'Mit unserer Leidenschaft für Natur und Design schaffen wir einzigartige Erholungsräume, die begeistern und inspirieren.'   

const txt2 =  'Du interessierst Dich für die Arbeit im Grünen und möchtest eine fundierte Ausbildung in einem zukunftssicheren Beruf? <br />' +
'Dann bewirb Dich bei uns für eine Ausbildung zum Landschaftsgärtner (m/w/d)! <br />' +
'Wir bieten Dir die Möglichkeit, deine Leidenschaft für Natur und Gestaltung zum Beruf zu machen.'

function Jobs(){
    const { label } = useWindowWidth();
    return (
        <>
            <UpperSpace />
            <h1 className='jobshead'>Jobs</h1>
            <PuzImgTxt text={txt1} img={img1} right/>
            <UpperSpace />
            <div className={`jobs ${label}`}>
                <h2>Warum bei uns arbeiten? Wir bieten Ihnen:</h2>
                <p>
                     - Ein engagiertes und freundliches Team: Bei uns arbeiten Sie in einem motivierten Team, das Wert auf Zusammenarbeit und gegenseitige Unterstützung legt. Wir fördern eine positive Arbeitsatmosphäre, in der sich jeder wohlfühlt und seine Ideen einbringen kann.<br /><br/>
                     - Vielfältige Projekte: Kein Tag ist wie der andere! Sie werden an einer Vielzahl spannender Projekte mitwirken, von kleinen Privatgärten bis hin zu großen öffentlichen Parks. Diese Vielfalt sorgt für abwechslungsreiche und interessante Aufgaben.<br /><br />
                     - Nachhaltigkeit: Wir setzen auf umweltfreundliche und nachhaltige Lösungen. Bei uns haben Sie die Möglichkeit, aktiv zum Schutz der Natur beizutragen und innovative grüne Projekte zu realisieren.
                </p>
                <h2>Offene Stellen</h2>
                <p>
                     - Facharbeiter (m/w/d) im Garten- und Landschaftsbau (Vollzeit)<br />
                     - Vorarbeiter / Geselle (m/w/d) im Garten- und Landschaftsbau (Vollzeit)<br />
                     - Gärtner (m/w/d) mit langjähriger Berufserfahrung (Vollzeit)<br />
                     - Mitarbeiter (m/w/d) im Garten- und Landschaftsbau (Vollzeit)
                </p>
            </div>
            <UpperSpace />
            <PuzImgTxt heading='Ausbildung' text={txt2} img={img2} left/>
            <UpperSpace />
            <div className={`jobs ${label}`}>
                <h3>Was wir erwarten:</h3>
                <p>
                    - Leidenschaft für Natur und Pflanzen <br />
                    - Handwerkliches Geschick und Kreativität<br />
                    - Teamfähigkeit und Zuverlässigkeit<br />
                    - Motivation und Lernbereitschaft<br />
                    - Mindestens einen Hauptschulabschluss
                </p>
                <h3>Was dich erwartet:</h3>
                <p>
                    - Abwechslungsreiche Tätigkeiten: Du lernst alles von der Planung und Anlage bis zur Pflege von Gärten und Grünanlagen.<br />
                    - Professionelle Anleitung: Unsere erfahrenen Ausbilder und Kollegen stehen Dir stets zur Seite und unterstützen Dich.<br />
                    - Theorie und Praxis: Du erhältst eine umfassende Ausbildung, die Praxis im Betrieb und Theorie in der Berufsschule optimal kombiniert.<br />
                    - Moderne Technik: Du arbeitest mit modernsten Geräten und lernst den Umgang mit innovativen Techniken und Materialien.
                </p>
                <h3>Was wir bieten:</h3>
                <p>
                    - Familiäres Arbeitsumfeld: Ein herzliches Team, das Wert auf ein gutes Miteinander legt.<br />
                    - Gute Übernahmechancen: Bei erfolgreichem Abschluss bieten wir Dir hervorragende Aussichten auf eine Übernahme.
                </p>
                <UpperSpace />
                <h1 className='subhead'>Bewerbung</h1>
                <p> 
                    Haben wir Ihr Interesse geweckt? Dann freuen wir uns auf Ihre Bewerbung!<br /> <br/>
                    Bitte senden Sie Ihre vollständigen Unterlagen (Anschreiben, Lebenslauf, Zeugnisse) ein. <br /> <br />
                    Sollten Sie Fragen zur Stelle oder zum Bewerbungsprozess haben, zögern Sie nicht, uns zu kontaktieren. Unser freundliches Team steht Ihnen gerne zur Verfügung und beantwortet Ihre Fragen.
                </p>
                <UpperSpace />
            </div>
        </>
    );
}

export default Jobs;