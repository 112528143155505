import { PuzImgGallery, PuzTxtImgTxt, UpperSpace } from "./allserv";
import img1 from './../../img/3gWasseranlagen/Schwimmteichbau/Schwimmteichbau_20.JPG';
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images = importAllImages(require.context("./../../img/3gWasseranlagen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const txt1 = 
"Das Element Wasser verwandelt Gärten in Oasen. Wasser gilt als Quell des Lebens.<br>"+
"Wasserflächen vervollkommnen unsere Gärten und bieten für Pflanzen und Tiere zusätzlichen Lebensraum."

const txt2 = 
"Das leichte Plätschern des Wassers über einen Bachlauf oder einem Wasserspiel, "+ 
"die klaren und dann wieder verschwommenen Bilder auf dem Wasserspiegel sorgen für ein beruhigendes "+
"und entspanntes Flair in unseren Gärten. Wasserflächen vergrößern aber auch optisch den "+
"Raum, sorgen für Erfrischung und bilden ein lebendiges Biotop mitten in Ihrem Garten. Flora "+
"und Fauna gehen in Gartenteichen Hand in Hand. Ob Naturteich oder Schwimmteich, die "+
"Vorbereitungsarbeiten für Ihren Gartenpool oder den Einbau von Wasserbecken und "+
"Wasserspielen – in Zusammenarbeit mit einem kompetenten Partner (Planung und Einbau "+
"Teichtechnik, Verlegen von Teichfolie) bauen wir Ihre Wasseranlage."

// site Erdarbeiten und Entwässerung
export default function WA () {
    return(
        <>
            <UpperSpace />
            <PuzTxtImgTxt heading="Wasseranlagen" text1={txt1} text2={txt2} img={img1}/>
            <UpperSpace />
            <PuzImgGallery imgs={images}/>
            <UpperSpace />
        </>
    );
}