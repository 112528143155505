// base
import { PuzImgGallery, PuzTxtImgTxt, PuzSteckbrief, UpperSpace} from "./allserv";
import img from "./../../img/3cWegegestaltungundPflasterbau/Eingänge/Eingänge_80.JPG"
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images = importAllImages(require.context("./../../img/3cWegegestaltungundPflasterbau", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

// the text displayed on the right
const txt1 = 
"Bei der Wegegestaltung geht es nicht nur um Gartenwege, sondern auch um die Herstellung einer Zufahrt zum Grundstück, " + 
"der Einfahrt zur Garage, von Parkplätzen und Stellflächen, Sitzplätzen und Terrassen, Trittsteinen im Rasen bis hin zur kreativen Innenhofgestaltung, " +
"verbunden mit den dafür erforderlichen Einfassungen und Stufen bis hin zu großen Treppenanlagen. " + 
"Hier ist der Einsatz unterschiedlichster Materialien wie Holz, Naturstein, Betonstein, Klinker oder Keramik möglich. " +
"Pflasterungen müssen der Witterung, den Umwelteinflüssen und zum Teil großen Belastungen (Gewicht eines Autos/ Lkws, Drehen der Räder usw.) standhalten."

const txt2 = 
"Der Untergrund und die Frostschutz- und Schottertragschichten müssen gut vorbereitet und fachgerecht ausgeführt werden, " + 
"um spätere Fahrspuren oder Pflasterabsackungen zu verhindern. Lassen Sie sich bei Pflasterarbeiten also vom Fachmann unterstützen. " +
"Durch die kreative Wegegestaltung erhält Ihre Gartenanlage ihren eigenen, individuellen Charakter ganz nach Ihren Wünschen und Vorstellungen. " + 
"Dabei werden auch die Beschaffenheit des Untergrundes, die jeweiligen Nutzungen und sonstigen Anforderungen berücksichtigt. " +
"Wir finden für all diese Fragen die richtige Lösung und unterstützen Sie bei der richtigen Materialwahl und der idealen Wegführung."

// die Aufzählung der Leistungen
const leistungen = [
    "Herstellung von Einfassungen aus Borden (Beton- oder Naturstein), aus Metall (Alu-, Stahl- oder Edelstahlliner, gebogen oder gerade ausgeführt), aus 1-, 2- oder mehrreihigen Pflasterläufern und Klinkerrollschichten",
    "Pflasterarbeiten aus Betonstein in verschiedenen Verbänden, engfugig verlegt oder als Ökopflaster mit Splitt- oder Rasenfuge",
    "Pflasterarbeiten aus Naturstein in ungebundener oder gebundener Bauweise",
    "Verlegung von Klinkerpflastersteinen",
    "Plattenverlegung aus Betonplatten, Natursteinplatten, Rasenplatten, Fallschutzplatten – auch große Formate mithilfe spezieller Vakuumtechnik",
    "Herstellung von Pflasterkreisen, Ornamenten, Symbolen oder einfachen Motiven in der Pflasterfläche",
    "Verlegung von Trittsteinen und Platten im Rasen",
    "Herstellung von wassergebundenen Wegedecken, Kies-, Schotterwegen und -flächen",
    "Herstellung von Rampen und Schrägen für die barrierefreie Erschließung",
    "Herstellung von Stufen, Podesten bis hin zu großen Treppenanlagen",
    "Herstellung von gepflasterten Entwässerungsrinnen oder -mulden",
    "die zugehörigen Schneidarbeiten bzw. Anpassen der Natursteine durch Knacken",
]

// site Erdarbeiten und Entwässerung
export default function WP () {
    return(
        <>
            <UpperSpace />
            <PuzTxtImgTxt 
                heading="Wege- und Platzgestaltung, Terrassen"
                img={img}
                text1={txt1}
                text2={txt2}
            />
            <UpperSpace />
            <PuzSteckbrief leistungen={leistungen}/>
            <UpperSpace />
            <PuzImgGallery imgs={images}/>
        </>
    );
}