import { PuzImgGallery, PuzTxtImgTxt, PuzSteckbrief, UpperSpace, PuzImgTxt} from "./allserv";
import img1 from "./../../img/3eRasenundBepflanzung/Rollrasen/Rollrasen_50.jpg"
import img2 from "./../../img/3eRasenundBepflanzung/Pflanzarbeiten/Pflanzarbeiten_270.jpg"
import img3 from "./../../img/3eRasenundBepflanzung/Rasenansaat/Rasenansaat_20.jpg"
import img4 from "./../../img/3eRasenundBepflanzung/Rollrasen/Rollrasen_60.jpg"
import img5 from "./../../img/3fGarten-undLandschaftspflege/Pflege/Pflege_05.jpg"
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images = importAllImages(require.context("./../../img/3eRasenundBepflanzung", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const txt1 =
"Rasenflächen sind das Grundelement jedes Gartens. Ihr samtiges Grün ist unbestreitbar schön und eine Wohltat für das Auge. " +
"Wir können auf ihnen spielen oder uns in den Schatten eines Baumes legen. " +
"Rasenflächen führen zu Pflanzflächen hin oder geben ihnen einen grünen Rahmen, von dem sich die Pflanzung abheben und besser wirken kann. " +
"In Parkanlagen und privaten Gärten steht die Erholungsfunktion im Vordergrund, auf Spielplätzen ist Robustheit und höchste Belastbarkeit gewünscht."

const txt2 = 
"Rasenflächen produzieren lebenswichtigen Sauerstoff, filtern Staubpartikel aus der Luft und sind deshalb durch ihre Klima verbessernde Wirkung aus dem modernen Stadtbild nicht mehr wegzudenken. " +
"Rasenflächen können sowohl durch Aussaat als auch mit Fertigrasen angelegt werden. " + 
"Die Vorbereitung der Flächen ist mit größter Sorgfalt durchzuführen, damit eine schöne Rasenfläche entstehen kann."

const txt3 = 
"Eine Möglichkeit, eine Rasenfläche zu erstellen, ist die Rasenansaat. Dies ist die einfachere und kostengünstigere Variante. " +
"Es dauert nach der Rasenansaat aber länger, bis man die Fläche nutzen kann, da der Rasen mindestens 10 Tage braucht, um zu keimen. " +
"In der Regel vergehen 12 Monate bei regulärer Aussaat, bis die angelegten Flächen wunschgemäß genutzt und belastet werden können. " +
" Wir verwenden nur hochwertiges Saatgut, v.a. Regelsaatgutmischungen."

const txt4 =
"Dies ist die aufwendigere Arbeit, der Rasen wird in Rollen geliefert und auf der Fläche ausgerollt. "+ 
"Im Vergleich zur Ansaat ist die Fläche sofort grün und bei guter Bewässerung und Pflege ist es schon möglich, den Rasen nach 2 Wochen voll zu nutzen."

const txt5 =
"Damit sich Ihr neu angelegter Rasen innerhalb kürzester Zeit zu einer ansehnlichen Grünfläche entwickelt und für die Übernahme der Garantie für die Rasenfläche, ist eine Fertigstellungspflege erforderlich. "+
"Wenn Sie diese Arbeiten selbst durchführen wollen, sollten Sie jedoch einige nützliche Tipps beachten, damit sich Ihr Rasen möglichst schnell entwickelt und Sie ihn auch entsprechend nutzen können:<br><br> "+
"- Rasenfläche für mindestens 2-3 Wochen dauerhaft feucht halten<br> "+
"- Regelmäßiger, wöchentlicher Schnitt auf eine Höhe von 4 cm<br> "+
"- Rasen in der Zeit von März bis Mitte September in regelmäßigen Abständen mit einem handelsüblichen Rasendünger düngen"

const txt6 =
"Ein Garten drückt den Geschmack und die Persönlichkeit seines Besitzers aus. "+ 
"Durch das Bepflanzen erhält Ihr Garten einen individuellen Charakter. "+
"Wir erstellen für Sie einen durchdachten Bepflanzungsplan, der Ihre Vorstellungen und Wünsche aufnimmt, angepasst an die gegebenen Standortbedingungen, mit Pflanzennamen und eine empfohlene Anzahl der vorgeschlagenen Pflanzen. "+
"Bäume, Sträucher und Hecken bilden das Grundgerüst des Gartens, gliedern Ihre Außenanlage in verschiedene Räume, sorgen für Beschattung und machen besonders im Sommer den Aufenthalt für Nutzer angenehmer. "+
"Wir pflanzen Ihren Lieblingsbaum oder eine Sichtschutzhecke im Privatgarten oder übernehmen Baumpflanzungen auf öffentlichen Plätzen, entlang Straßen oder auf Parkplätzen."

const txt7 =
"Bei der Pflanzung von Stadtbäumen müssen einige Anforderungen erfüllt werden: "+ 
"Größe des Wurzelraumes, Verwendung spezieller Baumpflanzsubstrate, Herstellung einer Baumscheibe als Schutz gegen Verdichtung, Belüftungs- und Bewässerungseinrichtungen, Baumverankerung, Fertigstellungs- und Entwicklungspflege. "+ 
"Diese Leistungen führen wir entsprechend der FLL-Empfehlungen gern fachmännisch für Sie aus. "+
"Zur Ergänzung des Grundgerüsts aus Bäumen, Sträuchern und Hecken planen und pflanzen wir für Sie standortgerechte und abwechslungsreiche Mischpflanzungen aus Kleinsträuchern, Stauden, Gräsern, Bodendeckern und Blumenzwiebeln, welche den "+
"Wechsel der Jahreszeiten berücksichtigen und den Wandel der Natur für Sie erlebbar machen. "+ 
"Die Gestaltung mit Pflanzen darauf abzustimmen und saisonale Höhepunkte zu schaffen, ist eine spannende Herausforderung."

const leistungen = [
    "Erstellung von individuellen Pflanzplänen, Beratung zur Sortenauswahl",
    "Lieferung und Einbau von humosem Oberboden, speziellen Pflanz- oder Rasensubstraten, Bodenvorbereitung, Fräsen/ Bodenlockerung",
    "Geländemodulierung, Terrassierung",
    "Bodenverbesserung der Pflanz- und Rasenflächen, Düngung",
    "Herstellung eines Feinplanums für Pflanz- und Rasenflächen",
    "Rasenansaat oder Rollrasenverlegung",
    "Verlegung von Böschungsmatten zur Böschungssicherung, Einbau von Faschinen",
    "Lieferung und Pflanzung von Bäumen, Sträuchern, Hecken, Stauden, Gräsern, Blumenzwiebeln",
    "Fachgerechter Pflanzschnitt/ Rückschnitt",
    "Verankerung/ Sicherung von Gehölzen",
    "Mulchen der Pflanzflächen mit Rindenmulch, Rosenmulch oder Pinienrinde in verschiedenen Körnungen",
    "Anlegen von Kiesbeeten oder Beeten mit mineralischer Mulchschicht",
    "Herstellung oder Aufbau von Hochbeeten verschiedenster Arten, einschließlich Füllung und Bepflanzung",
    "Bepflanzung von Blumenkübeln",
    "Bepflanzung von Gräbern",
    "Lieferung und Montage von Rankgerüsten und Pergolen, Gewächshäusern, einschließlich Fundamentierung",
    "Pflege der Bepflanzung und der Rasenflächen"
]

// site Erdarbeiten und Entwässerung
export default function RB () {
    return(
        <>
            <UpperSpace />
            <PuzTxtImgTxt heading={"Rasen"} text1={txt1} text2={txt2} img={img1}/>
            <PuzImgTxt heading={"Rasenansaat"} text={txt3} img={img3} left sub/>
            <PuzImgTxt heading={"Rollrasen"} text={txt4} img={img4} sub/>
            <PuzImgTxt heading ={"Rasenpflege"} text={txt5} img={img5} left sub/>
            <UpperSpace />
            <PuzTxtImgTxt heading={"Bepflanzung"} text1={txt6} text2={txt7} img={img2}/>
            <UpperSpace/>
            <PuzImgGallery imgs={images}/>
            <UpperSpace />
            <PuzSteckbrief leistungen={leistungen}/>
            <UpperSpace />
        </>
    );
}