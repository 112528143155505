import { PuzImgGallery, PuzTxtImgTxt, PuzSteckbrief, UpperSpace, PuzImgTxt} from "./allserv";
import img1 from "./../../img/3hSonstiges/Beton Fundamente/BetonFundamente_00.JPG"
import img2 from "./../../img/3hSonstiges/Brückenbau Holzdecks/2014 Brücke Großburschla/img_06.jpg"
import img3 from "./../../img/3hSonstiges/Bänke/Bänke_100.jpg"
import img4 from "./../../img/3hSonstiges/Ausstattungsobjekte/Ausstattungsobjekte_020.JPG"
import img5 from "./../../img/3hSonstiges/Spielplatzbau/Spielplatzbau_270.jpg"
import img6 from "./../../img/3hSonstiges/Öffentliche Plätze/ÖffentlichePlätze_0.jpg"
import img7 from "./../../img/3hSonstiges/Friedhöfe/2021 Friedhof Mihla/img_04.jpg"
import img8 from "./../../img/3hSonstiges/Zusammenarbeit mit Subunternehmen/Asphalt/img_06.jpg"
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};

const images00 = importAllImages(require.context("./../../img/3hSonstiges/Beton Fundamente", true, /\.(png|jpe?g|JPG|svg)$/));
const images01 = importAllImages(require.context("./../../img/3hSonstiges/Brückenbau Holzdecks", true, /\.(png|jpe?g|JPG|svg)$/));
const images02 = importAllImages(require.context("./../../img/3hSonstiges/Bänke", true, /\.(png|jpe?g|JPG|svg)$/));
const images03 = importAllImages(require.context("./../../img/3hSonstiges/Ausstattungsobjekte", true, /\.(png|jpe?g|JPG|svg)$/));
const images04 = importAllImages(require.context("./../../img/3hSonstiges/Spielplatzbau", true, /\.(png|jpe?g|JPG|svg)$/));
const images05 = importAllImages(require.context("./../../img/3hSonstiges/Öffentliche Plätze", true, /\.(png|jpe?g|JPG|svg)$/));
const images06 = importAllImages(require.context("./../../img/3hSonstiges/Friedhöfe", true, /\.(png|jpe?g|JPG|svg)$/));
const images07 = importAllImages(require.context("./../../img/3hSonstiges/Zusammenarbeit mit Subunternehmen", true, /\.(png|jpe?g|JPG|svg)$/));

const txt1 =
"Streifenfundamente für Mauern, Punktfundamente für Pfeiler, Pfosten, diverse "+
"Ausstattungsgegenstände, Bodenplatten für Schwimmteichbau, Poolbau, Gartenhäuser, "+
"Carports - unsere kompetenten Mitarbeiter bieten Ihnen komplette Betonarbeiten - vom "+
"Einschalen bis zum Betonieren."

const txt2 =
"Holz ist ein emotionales Gestaltungselement mit Charakter, dass Ihrem Garten einen besonderen Flair verleiht."

const txt3 =
"Wir führen für Sie aus:<br>"+
"- Kleinere Holzbrücken in Zusammenarbeit mit einem Fachmann (Tischler, Zimmermann)<br>"+
"- Holzterrassen oder Terrassen aus WPC <br>"+ 
"- Holzdecks über Teichen<br>"+
"- Sitzbankauflagen aus Holz"

const txt4 = 
"Um im eigenen Garten, in einem Park, auf einem öffentlichen Platz oder zur Pause auf dem "+
"Firmengelände zu verweilen, zu entspannen und die Natur zu genießen, bieten wir Ihnen "+
"verschiedenste Sitzmöglichkeiten, Bänke und Liegen aus unterschiedlichsten Materialien an.<br>" +
"Sie haben die Wahl."

const txt5 =
"Ausstattungs- und Kunstobjekte bilden Blickfänge und unterstützen den Charakter des Gartens "+
"und seiner Besitzer. Sie sind eine interessante Idee für alle, die etwas von Garten und Kunst "+
"verstehen."+
"Von einfachen Ausstattungsgegenständen, wie Fahrradständer, Papierkörbe, Wäschestangen "+
"und -spinnen bis hin zu handgefertigten Grills, Brunnen, Grillkotas, Fertiggaragen oder "+
"Trampolinen – wir liefern und bauen Ihnen Ihre Lieblingsobjekte ein."

const txt6 =
"Durch ein gut gewähltes und passend platziertes Kunstobjekt kann Ihr Garten an "+
"Einzigartigkeit dazugewinnen und auch bei weniger Gartenbegeisterten Bewunderung "+
"auslösen. Das Kunstobjekt sollte mit seiner Umgebung verschmelzen können und zu einem "+
"großen ästhetischen Eins werden."

const txt7 =
"Spielplätze bauen bedeutet, sichere aber auch abwechslungs- und erfahrungsreiche, "+
"interessante Erlebniswelten für Kleinkinder, Kinder und Jugendliche zu schaffen, in denen sie "+
"ihre Kreativität und motorischen Fähigkeiten optimal entwickeln und entfalten können. Die "+
"Berücksichtigung der DIN-Normen und der Sicherheitsaspekte sind dabei für uns "+
"selbstverständlich."

const leistungen1 = [
    "Lieferung und Montage von Spielplatzgeräten unter Berücksichtigung der DIN EN 1176/1177",
    "Spielsandeinbau und -austausch",
    "Lieferung und Einbau zertifizierter Fallschutzmaterialien",
    "Entwicklung neuer Spielplatzgestaltung",
    "Abnahme des hergestellten Spielplatzes durch einen zertifizierten Spielplatzprüfer"
]

const txt8=
"Einer unserer Arbeitsschwerpunkte ist die Neugestaltung von öffentlichen Plätzen und "+
"Grünanlagen für Auftraggeber aus den Bereichen Kommune, Land oder Bund, die Schaffung "+
"von Oasen der Entspannung im urbanen Raum – das wird wohl auch die wichtigste "+
"landschaftsgärtnerische Aufgabe der Zukunft sein! "+
"Öffentliche Plätze, Schulen, Spiel- und Sportanlagen, Parks und selbst Straßen sind Orte, an "+
"denen Menschen sich in Ballungsräumen begegnen!" 

const txt9=
"Durch eine gekonnte Gestaltung und Begrünung dieser Orte entstehen Natur-Inseln, die "+
"durch ihre Schönheit und ihren Erlebniswert die Lebensqualität der Einwohner erheblich "+
"steigern – und auch das Klima im städtischen Raum entscheidend verbessern!"

const txt10=
"Ein weiterer Arbeitsschwerpunkt ist die Umgestaltung von Friedhöfen, die Neuanlage "+
"von Grabflächen unterschiedlicher Bestattungsformen, die Anlage von Wegen, "+
"Wasserstellen, Sitzmöglichkeiten zum Verweilen, die Grundbepflanzung des Friedhofes, aber "+
"auch die Bepflanzung einzelner Grabstellen."

const txt11=
"Um sämtliche anfallende Arbeiten im Bereich der Außenanlagen ausführen zu können, "+
"arbeiten wir eng mit vielen Subunternehmen unterschiedlichster Branchen zusammen."

const leistungen2=[
    "Elektroarbeiten: Kabelverlegung, Installation von Wege- und Straßenbeleuchtung, Versorgungspollern, Außensteckdosen, Stromversorgung Ihres Pools oder Wasserspiels",
    "Holzarbeiten: Individuell gefertigte Pergolen, Rankgerüste, Pavillons, Gartenhäuser, Zäune oder Einhausungen, Kunstobjekte",
    "Schlosserarbeiten: Individuell hergestellte Schmuckzaunanlagen, Türen und Tore, Rankgerüste, Handläufe, Absturzsicherungen, Beeteinfassungen oder Hochbeete aus Cortenstahl, Kunstobjekte",
    "Steinmetzarbeiten: für Schmucksteine und Wappen in Mauern, Torbögen, Pflanztröge aus Stein usw.",
    "Betonarbeiten: Herstellung von Schwerlastmauern, Mauerverankerung mit Ischebeck- Schwerlastankern, Sichtbetonmauern und -wänden",
    "Asphaltarbeiten: Zufahrten und Parkplätze, Gestaltung von welligen Fahrstrecken, Einbau mit Fertiger oder von Hand",
    "Fugenloser Fallschutzbelag für Spielplätze und spezielle Spielgeräte (Trampolin)"
]

// site Erdarbeiten und Entwässerung
export default function SO () {
    return(
        <>
            <UpperSpace />
            <PuzImgTxt heading="Betonarbeiten und Fundamente, Bodenplatten" text={txt1} img={img1} left/>
            <PuzImgGallery imgs={images00}/>
            <UpperSpace />

            <PuzTxtImgTxt heading="Brückenbau und Holzdecks" text1={txt2} text2={txt3} img={img2}/> 
            <PuzImgGallery imgs={images01}/>
            <UpperSpace />

            <PuzImgTxt heading="Bänke" text={txt4} img={img3} left/>
            <PuzImgGallery imgs={images02}/>
            <UpperSpace />

            <PuzTxtImgTxt heading="Ausstattungsobjekte" text1={txt5} text2={txt6} img={img4}/>
            <PuzImgGallery imgs={images03}/>
            <UpperSpace />

            <PuzImgTxt heading="Spielplatzbau und Kindergärten/ Schulen" text={txt7} img={img5} left/>
            <PuzSteckbrief heading="Unsere Leistungen im Spielplatzbau:" leistungen={leistungen1}/>
            <UpperSpace />
            <PuzImgGallery imgs={images04}/>
            <UpperSpace />

            <PuzTxtImgTxt heading="Öffentliche Plätze und Grünanlagen" text1={txt8} text2={txt9} img={img6}/>
            <PuzImgGallery imgs={images05}/>
            <UpperSpace />

            <PuzImgTxt heading="Friedhöfe" text={txt10} img={img7} left/>
            <PuzImgGallery imgs={images06}/>
            <UpperSpace />
            
            <PuzImgTxt heading="Zusammenarbeit mit Subunternehmen" text={txt11} img={img8}/>
            <UpperSpace />
            <PuzSteckbrief leistungen={leistungen2}/>
            <UpperSpace />
            <PuzImgGallery  imgs={images07}/>
            <UpperSpace />
        </> 
    );
}