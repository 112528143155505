import { PuzImgGallery, PuzTxtImgTxt, PuzSteckbrief, UpperSpace } from "./allserv";
import img1 from "./../../img/3dZäuneundMauern/Zaunbau/Zaunbau_060.jpg"
import img2 from "./../../img/3dZäuneundMauern/Mauerbau/Mauerbau_330.jpg"
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images = importAllImages(require.context("./../../img/3dZäuneundMauern", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const txt1 = 
"Ein Zaun bietet Ihrem Grundstück Sicherheit, schafft Privatsphäre, einen geschützten Raum für Kinder und Haustiere. " +
"Durch ihn erfolgt eine Trennung zu unmittelbar angrenzenden Arealen, verwehrt ungebetenen Personen den Zutritt zum Grundstück und bildet für Wildtiere eine Barriere. " +
"Je nach Ausführung kann ein Zaunsystem zu einem attraktiven Gestaltungselement ihrer Gartenanlage werden. " +
"Für ein optisch ansprechendes Ergebnis sollte der Zaun in Form, Farbe und Material zum Gebäudestil und zur Umgebung passen. " +
"Die Kombination verschiedener Materialien ist selbstverständlich möglich."

const txt2 = 
"Außerdem liefern und montieren wir Ballfangzäune, die zum Zaun passenden Tür- und Toranlagen in unterschiedlichen Breiten und Ausführungen. " +
"Die Frage nach der Höhe eines Zaunes zur Grenzbebauung regelt das örtliche Nachbar- und Bauordnungsrecht. " +
"Das bedeutet, dass Sie sich vor dem Bau beim Bauamt über die Rechtslage in ihrem Ort informieren sollten. " +
"Ein blickdichter Zaun als Sichtschutz hat in der Regel Höhen von 1,60m bis 1,80m. " +
"Bei geschlossenen Sichtschutzzäunen muss die Windbelastung berücksichtigt und evtl. verstärkte Pfosten eingeplant werden. " +
"Egal ob Sie eine Umzäunung für den privaten, gewerblichen oder öffentlichen Bereich benötigen - wir finden die für Sie passende Zaunlösung."

const leistungen = [
    "Stabmattenzaun, mit oder ohne Flechtstreifen",
    "Metallzaun mit Schmuckelementen",
    "Maschendrahtzaun",
    "Zaungabionen, in Kombination mit Holzsichtschutzelementen oder grünen Hecken",
    "Sichtschutzzäune",
    "Holzlatten- und Holzbohlenzäune"
]

const txt3 = 
"Für jedes Grundstück sind gut gestaltete Gartenmauern ein echter Gewinn und Hingucker. " + 
"Sie erfüllen verschiedene Funktionen: so zum Beispiel als Sichtschutz, effektiver Lärmschutz, zum Abfangen von Höhenunterschieden und zur Terrassierung des Geländes. " +
"Sie sind außerdem geeignet als Treppenwangen oder einfach als optisches Trenn-Element zur Gliederung des Außenbereiches. " +
"Mauern können aus Betonmauersteinen, Natursteinen, Klinkern, Stützwinkeln, Gabionen, Palisaden und Stelen hergestellt werden. " +
"Dabei kann ihre Form sowohl gerade oder geschwungen sein, die Höhe und Länge variieren und eine Abdeckplatte optional ergänzt werden."

const txt4 = 
"Vor allem der Bau von Natursteinmauern bedarf sehr viel Erfahrung und handwerklichem Geschick. " +
"Jede Natursteinmauer wird zu einem Unikat, da hier verschiedene natürliche Steine in unterschiedlichen Farben und Formen verwendet werden können. " + 
"Man hat außerdem die Auswahlmöglichkeiten zwischen: trocken aufgeschichtet oder aufgemauert, vollflächig verfugt oder mit Schattenfuge. " +
"Aus Mauersteinen und Gabionen können auch Sitzreihen mit Holzauflage oder komplette Amphitheater errichtet werden."


// site Erdarbeiten und Entwässerung
export default function ZM () {
    return(
        <>
            <UpperSpace />
            <PuzTxtImgTxt heading={"Zaunbau"} text1={txt1} text2={txt2} img={img1}/>

            <PuzSteckbrief heading={"Beliebte Zaunarten sind:"} leistungen={leistungen} left/>

            <UpperSpace />
            <PuzTxtImgTxt heading={"Mauerbau"} text1={txt3} text2={txt4} img={img2}/>
            <PuzImgGallery imgs={images}/>

            <UpperSpace />
        </>
    );
}