// css:
import './../css/info.css';
import './../css/allserv.css';

// images:

// others:
import { InfoBox } from './../sides/allserv/allserv';
import { UpperSpace } from './../sides/allserv/allserv';
import { useWindowWidth } from '../../App';

const txt1 = 
'Sogenannte Handwerksleistungen sind einmalige Arbeiten wie zum Beispiel Reparaturen, ' +
'Erdaufschüttungen, Anlegen von Gartenteich oder Pool, die Bohrung eines Brunnens oder ' +
'das Verlegen von Pflastersteinen, zum Beispiel beim Bau einer Terrasse. ' +
'Doch nicht nur die Arbeitskosten der handwerklichen Tätigkeiten gehören zu den ' +
'Handwerksleistungen. Auch Lohn-, Maschinen- und Fahrtkosten, einschließlich der ' +
'Mehrwertsteuer, sowie die Kosten für Verbrauchsmittel wie beispielsweise Treibstoff gehören dazu. <br/> <br/>'+
'Pro Jahr kann man 20 Prozent von maximal 6000 Euro der angefallenen Kosten ' +
'von der Steuer absetzen, also insgesamt 1200 Euro. ' +
'Übersteigen die Aufwendungen voraussichtlich den Höchstbetrag von 6.000 Euro, empfiehlt ' +
'sich durch Vorauszahlungen oder Ratenzahlungen eine Verteilung auf zwei Jahre. ' +
'Entscheidend für den Abzug ist immer das Jahr, in dem die Gesamtrechnung beglichen oder ' +
'eine Rate überwiesen wurde. Wenn Sie ein Unternehmen engagieren, das entsprechende ' +
'Arbeiten für Sie ausführt, müssen Sie darauf achten, dass es ordentlich gemeldet ist. ' +
'Bezahlte Dienstleistungen von Freunden oder Nachbarn, die kein Gewerbe angemeldet ' +
'haben, können nicht angeführt werden.'

const txt2 =
'Haushaltsnahe Dienstleistungen beinhalten ständig anfallende Pflege- und ' + 
'Instandhaltungsarbeiten wie Rasenmähen, die Bekämpfung von Schädlingen und ' +
'Heckenschneiden. Von der Steuer absetzen, können Sie dabei 20 Prozent von maximal ' +
'20.000 Euro, was 4.000 Euro entspricht. <br/>' +
'Wenn die Kosten nicht auf dem eigenen Grundstück anfallen, wie beispielsweise beim ' +
'Winterdienst auf der Anliegerstraße, dürfen diese nicht geltend gemacht werden. '+
'Außerdem wirken Materialkosten wie gekaufte Pflanzen oder Verwaltungsgebühren sowie die Kosten ' +
'für Entsorgung und Gutachtertätigkeiten nicht steuermindernd. <br/><br/>' +
'Bewahren Sie Rechnungen mindestens zwei Jahre auf und weisen Sie die gesetzliche Mehrwertsteuer aus. ' +
'Viele Finanzämter erkennen die genannten Kosten nur an, wenn der ' +
'Zahlungsbeleg, etwa eine Quittung oder ein Überweisungsträger mit passendem ' +
'Kontoauszug, zur entsprechenden Rechnung beigelegt wird. Führen Sie außerdem die ' +
'Materialkosten getrennt von den Arbeits-, Fahrt- und Maschinenkosten auf, denn lediglich die ' +
'letzten drei Kostenarten können Sie von der Steuer absetzen. <br/><br/> ' +
'Wichtig: Begleichen Sie abzugsfähige Rechnungen niemals in bar, sondern immer per ' +
'Banküberweisung – nur so lässt sich der Geldfluss rechtssicher dokumentieren, falls das Finanzamt nachfragt.'

const txt3 = 
'Rasenflächen verändern sich in ihren Eigenschaften und insbesondere in der ' +
'Artenzusammen-setzung durch atmosphärische und biologische Einflüsse sowie durch die ' +
'Benutzung und Pflege. Sie unterliegen so naturgemäß einem ständigen Wandel. <br/><br/>' +
'Der örtlich vorhandene Boden sowie ggfls. von uns als Vegetationstragschicht gelieferter ' +
'Oberboden oder Bodengemische (Substrat) enthalten grundsätzlich einen nicht ' +
'vermeidbaren Besatz an natürlicher Bodenflora und Bodenfauna (Samen von ' +
'unerwünschten Kräutern, Insektenlarven, Regenwürmer, Pilzmyzelien und dergleichen). ' +
'Infolgedessen können z. B. bei Rasenansaaten in der Anfangszeit und insbesondere bei ' +
'warmen und feuchten Bedingungen vermehrt bodenbürtige Kräuter, z.B. Melde, ' +
'Gänsefuß, falsche Kamille, Hirse, Fruchtkörper von Pilzen, auftreten. Diese sind in der Regel ' +
'für den Rasen unschädlich und werden mit zunehmender Rasendichte von den ' +
'Rasengräsern verdrängt. Bei störendem Erscheinungsbild werden sie ganz einfach mit dem Rasenmäher abgemäht. <br/><br/>' +
'Der örtlich vorhandene Boden und der ggfls. von uns gelieferte Oberboden enthalten immer ' +
'auch einen natürlichen Anteil an Steinen unterschiedlicher Korngrößen. Die Steine erfüllen ' +
'als strukturbildende Gerüstbaustoffe wichtige Bodenfunktionen. Sie bilden die ' +
'für Wachstum und Versorgung der Rasengräser erforderliche Hohlräume (Bodenporen), ' +
'fördern das Bodenleben und die Ableitung bzw. Versickerung von überschüssigem ' +
'Niederschlagwasser. Die Steinanteile variieren naturgemäß und richten sich nach der ' +
'entsprechenden Bodengruppe gemäß DIN 18915 „Vegetationstechnik im Landschaftsbau – Bodenarbeiten“. ' +
'Es lässt sich – trotz sorgfältigstem Abharken bei der Erstellung des Feinplanums – ' +
'grundsätzlich nicht vermeiden, dass sich z.B. infolge von Frosteinwirkungen, Niederschlägen ' +
'im Laufe der Zeit weitere im Boden befindliche Steine langsam nach oben arbeiten bzw. ' +
'sichtbar werden. Lose auf der Oberfläche liegende Steine sind festzutreten, störende Steine ' +
'sollten gegebenenfalls abgesammelt werden.'

const txt4 =
'In den ersten drei bis vier Wochen nach der Aussaat dürfen die oberste Bodenschicht und ' +
'die sich entwickelnden Keimlinge nicht austrocknen. Während dieser Zeit sind die ' +
'Saatflächen ausreichend feucht zu halten und bei ausbleibenden natürlichen Niederschlägen ' +
'täglich, gegebenenfalls mehrfach, drei- bis fünfmal, für die Dauer von jeweils ca. 5 – 10 ' +
'Minuten zu beregnen. Dabei dürfen sich auf der Fläche keine Pfützen bilden.'

const txt5 = 
'Der Schnitt des Rasens ist bei einer Wuchshöhe von 8 - 10 cm durchzuführen. Dabei wird ' +
'der Rasen auf 5 - 6 cm eingekürzt. Um einen sauberen Schnitt zu gewährleisten, müssen die ' +
'Messer des Mähers scharf sein. Stumpfe Messer reißen möglicherweise noch nicht fest ' +
'verwurzelte Gräser aus dem Boden. Außerdem hinterlassen sie ausgefranste Wundränder ' +
'an den Rasenblättern, in die Krankheitserreger leichter eindringen können. Die besten ' +
'Schnittergebnisse lassen sich mit einem Spindelmäher erzielen. Das abgeschnittene Mähgut ' +
'ist von der Rasenfläche zu entfernen.'

const txt6 =
'Nach dem ersten Schnitt sollte mit ca. 5 g/m2 Stickstoff gleichmäßig gedüngt werden. ' + 
'Die Empfehlungen der Düngerhersteller sind zu beachten. ' +
'Nach etwa vier bis sechs Wochen können Sie die neue Rasenfläche nutzen. Dies darf in den ' +
'ersten Wochen jedoch nur schonend erfolgen. Nun beginnen die regelmäßigen Leistungen ' +
'der Entwicklungs- und Unterhaltungspflege. Detaillierte Informationen finden Sie in der Anlage.'

const txt7 =
'In den ersten 2 bis 3 Wochen nach dem Verlegen ist der Fertigrasen ausreichend feucht zu ' +
'halten und bei zu geringen oder ausbleibenden natürlichen Niederschlägen täglich ' +
'durchdringend mit 15 -20 Litern pro m² zu beregnen.'

const txt8 = 
'Der Schnitt des Rasens ist bei einer Wuchshöhe von 6 - 8 cm durchzuführen. Dabei wird der ' +
'Rasen auf 4 - 5 cm eingekürzt. Um einen sauberen Schnitt zu gewährleisten, müssen die ' +
'Messer des Mähers scharf sein. Stumpfe Messer hinterlassen ausgefranste Wundränder an ' +
'den Rasenblättern, in die Krankheitserreger leichter eindringen können. Die besten ' +
'Schnittergebnisse lassen sich mit einem Spindelmäher erzielen. Das abgeschnittene Mähgut ' +
'ist von der Rasenfläche zu entfernen.'

const txt9 =
'Regelmäßiges Mähen fördert die Dichte des Rasens. Bei einer Wuchshöhe von ca. 6 - 8 cm ' +
'wird der Rasen auf eine Höhe von ca. 3,5 - 5 cm eingekürzt. Als Faustregel für das Mähen ' +
'gilt: Reduzierung der Aufwuchshöhe um ein Drittel bis maximal 50 %. Je tiefer geschnitten ' +
'wird, desto empfindlicher werden die Gräser.'

const txt10 =
'Der Wasserbedarf der Rasengräser ist im Wesentlichen von den Temperatur- und ' +
'Bodenverhältnissen abhängig. Beim Ausbleiben von ausreichenden natürlichen ' +
'Niederschlägen wird der Rasen ein- bis zweimal pro Woche mit ca. 10 - 20 Litern pro m², ' +
'frühmorgens oder spätabends gewässert. Bitte beachten Sie, dass häufige und zu geringe ' +
'Wassergaben zu einer Wurzelverflachung führen.'

const txt11 =
'Der jährliche Nährstoffbedarf der Rasengräser wird durch Düngergaben in den Monaten ' +
'April, Juni und August gedeckt. Im Oktober sollte zur Verbesserung der Winterhärte noch ' +
'einmal mit einem kaliumbetonten Dünger nachgedüngt werden. Die jeweilige Menge und Art ' +
'der Düngung sind dem Nährstoffvorrat des Bodens, der Jahreszeit und dem aktuellen Bedarf ' +
'der Gräser anzupassen. Es sollten nur spezielle Rasenlangzeitdünger verwendet werden. ' +
'Die Empfehlungen der Düngerhersteller sind zu beachten.'

const txt12 = 
'Der Rasen wird jährlich im April und gegebenenfalls zusätzlich noch einmal im August / ' +
'September vertikutiert. Die Vertikutiermesser sollen dabei den Boden nur leicht berühren und ' +
'keinesfalls die Rasennarbe aufschlitzen. Durch Vertikutieren werden Rasenfilz, Moos, ' +
'Unkraut und flachwurzelnde Fremdgräser herausgearbeitet. Der für das Gräser- und ' +
'Wurzelwachstum notwendige Luftaustausch sowie die Wasser- und Nährstoffzufuhr werden ' +
'verbessert. Das herausgearbeitete Material ist von der Rasenfläche zu entfernen.'

const txt13 = 
'Moosbildung ist häufig eine Folge von zu tiefem Mähen und Nährstoffmangel. Außerdem ' +
'wird die Moosbildung begünstigt durch Staunässe und schattige Lagen. Staunässe lässt sich ' +
'dauerhaft nur durch eine entsprechende Dränage und Wasserableitung beseitigen. ' +
'Unkräuter sollten ausgestochen werden.'

const txt14 = 
'Laub sollte regelmäßig von der Rasenfläche entfernt werden, da sonst der Rasen ersticken kann. ' +
'Auch liegen gebliebenes Fallobst kann den Rasen schädigen.'

const txt15 = 
'In Abhängigkeit von Boden, Nutzung und Belastung der Rasenfläche können Verdichtungen ' +
'entstehen, die das Wachstum und die Entwciklung der Rasengräser beeinträchtigen. ' +
'Zur Verbesserung der Wasserdurchlässigkeit und des Boden-Luft-Austausches ist der Einsatz von Spezialmaschinen erforderlich.'

const txt16 = 
'Frisch gepflanzte Stauden und Gehölze verfügen noch nicht über genügend Wurzeln, um das ' +
'benötigte Wasser aus tieferen Bodenschichten aufnehmen zu können. Bis zum Einwurzeln ' +
'sind die Pflanzen auf regelmäßige Wassergaben angewiesen. Hier gilt: Lieber einmal ' +
'durchdringend wässern, als mehrfach nur geringe Mengen Wasser ausbringen. Gießen Sie ' +
'Ihre Pflanzen, wie die Natur das mit einem Landregen tut, mit einem Regner oder einer ' +
'feinen Gießbrause. Wässern Sie bitte bei heißer Witterung unbedingt am frühen Morgen ' +
'oder späten Nachmittag. Bei vollem Sonnenstand verdunstet mehr Wasser in der Luft, als ' +
'die Pflanzen aufnehmen können. <br>' +
'Der tatsächliche Wasserbedarf einer Pflanzung variiert je nach Bodenart und ' +
'Zusammensetzung der Pflanzung. Sandige und andere durchlässige Böden benötigen mehr Wasser als sandig-lehmige Böden oder gar ' +
'Tonböden. Die Pflanzung sollte auch noch im Wurzelbereich in einer Tiefe von ca. 20 cm ' +
'ausreichend feucht sein. <br>' +
'Kommt es zu eingerollten, schlappen Blättern oder gar zu vorzeitigem Laubfall, können die ' +
'Pflanzen schon geschädigt sein und bedürfen noch intensiverer Pflege. ' +
'Folgende Wassermengen sollen Ihnen als Richtwerte pro Wässerungsgang dienen: <br><br>' +
'- Staudenflächen ca. 5 Liter pro qm Pflanzfläche = ca. 1/4 - 1/2 Minute Gießzeit pro qm <br>' +
'- Gehölzflächen ca. 10 - 20 Liter pro Pflanze = ca. 1/2 Minute Gießzeit pro Stück <br>' +
'- Bäume ca. 50 - 100 Liter pro Baum = ca. 3 Minuten Gießzeit pro Stück<br><br>'+
'Bei Großbäumen müssen ggf. noch größere Wassergaben vorgesehen werden. ' +
'Die Zeitangaben beziehen sich auf einen „normalen“ Gartenschlauch mit 1/2 Zoll Durchmesser. ' +
'Immergrüne Gehölze (Koniferen, Rhododendren, Buchs, usw.) müssen auch im Winter regelmäßig gewässert werden.'

const txt17 = 
'Stauden und Gehölze werden in regelmäßigen Abständen etwa in der Zeit von Anfang April ' +
'bis Ende Juli idealerweise mit organisch-mineralischen Langzeitdüngern gedüngt. Danach ' +
'sollte nicht mehr gedüngt werden, damit die Pflanzen ausreifen können. Im guten ' +
'Fachhandel oder bei Ihrem Landschaftsgärtner erhalten Sie eine breite Auswahl an ' +
'geeigneten Spezialdüngern sowie eine auf die jeweiligen Pflanzen ausgerichtete Fachberatung.<br> ' +
'Der Dünger sollte großflächig ausgebracht und oberflächlich eingearbeitet werden. Um ' +
'Pflanzenschäden zu vermeiden, sollten keine schnell wirkenden Dünger eingesetzt werden. ' +
'Die Produktanleitung der einzelnen Dünger ist strikt einzuhalten.'

const txt18 = 
'Beim Gehölzschnitt beschränken Sie sich bei der Fertigstellungspflege bitte nur auf das ' +
'Entfernen abgebrochener oder abgestorbener Zweige und Äste. Bei Blütenpflanzen ' +
'entfernen Sie nur die verwelkten Blüten. Überlassen Sie darüber hinaus den jährlichen, ' +
'fachgerechten Pflegeschnitt Ihrem Landschaftsgärtner. '

const txt19 =
'Die Pflanzflächen sind von Beikräutern (Unkraut) freizuhalten, da diese unerwünschten ' +
'Kräuter und Gräser den neu gesetzten Pflanzen Wasser, Licht und Luft wegnehmen können. ' +
'Wir raten, das Erdreich regelmäßig zu lockern und Wildwuchs zu entfernen. Das Lockern ' +
'verhindert die Keimung des Unkrauts und senkt die Verdunstung. Achten Sie darauf, dass ' +
'Sie flach hacken, damit keine Wurzeln beschädigt werden und Sie somit das Anwachsen der ' +
'Pflanzen stören. Bitte auf keinen Fall chemische Unkrautvernichtungsmittel einsetzen! ' +
'Auf den Pflanzflächen kann eine Mulchschicht von ca. 3 bis 5 cm Stärke aufgebracht ' +
'werden. Dabei dürfen allerdings keine oberirdischen Pflanzenteile bedeckt werden. Als ' +
'Mulchmaterial wird Kompost, kompostierter Rindenmulch, Holzschnitzel o. ä. verwendet. ' +
'Bezugsquellen sind Kompostwerke oder der Fachhandel. Durch das Abdecken mit ' +
'Mulchmaterial wird unerwünschter Aufwuchs am Keimen gehindert und das natürliche ' +
'Bodenleben gefördert. Zudem wird das Austrocknen des Bodens verhindert.<br> ' +
'Zu beachten ist hierbei jedoch, dass bei Wässerungsgängen ein Teil des Wassers von der ' +
'Mulchschicht aufgenommen wird. Auch ein Teil des ausgebrachten Düngers wird von der ' +
'Mulchschicht „verbraucht“ und muss entsprechend ergänzt werden. Lassen Sie sich speziell ' +
'zu diesem Thema von Ihrem Landschaftsgärtner beraten.<br><br> ' +
'Wenn Sie diese grundlegenden Ratschläge beherzigen, sind wir sicher, dass Ihre ' +
'Neupflanzung gut anwachsen und gedeihen wird. Sollten Sie dennoch trotz sorgfältiger ' +
'Pflege Probleme mit Ihren Gehölzen und Stauden bekommen oder weitere Fragen zur ' +
'Pflege haben, können Sie sich jederzeit an Ihren Landschaftsgärtner wenden.'

const txt20 = 
'Ein Bauwerk im Garten- und Landschaftsbau ist abgeschlossen, wenn eine Abnahme ' +
'stattgefunden hat. Bei dieser muss der Auftraggeber anwesend sein. Wird die ' +
'Baumaßnahme nicht förmlich abgenommen, gilt sie automatisch 12 Werktage nach ' +
'Fertigstellungsmeldung durch die Stephan Goldmann GmbH als abgenommen. ' +
'Bei privaten Auftraggebern melden wir die Fertigstellung in der Regel mündlich an, danach ' +
'erfolgt eine Begehung der bearbeiteten Flächen bzw. Begutachtung der erbrachten ' +
'Leistungen, einschließlich mündlicher Abnahme. Falls Sie eine schriftliche Abnahme ' +
'wünschen, teilen Sie uns das bitte mit.'

const txt21 = 
'Bei Pflanzungen und Ansaaten kann erst nach geraumer Zeit eine Abnahme stattfinden, da ' +
'erst dann beurteilt werden kann, ob alle Pflanzen ordnungsgemäß angewachsen sind. Um ' +
'einen abnahmefähigen Zustand zu erreichen, müssen die Pflanzungen bis zum Erreichen ' +
'dieses Zustandes vom Auftragnehmer gepflegt werden. Diese Pflege nennt man ' +
'Fertigstellungspflege. Ohne die Beauftragung einer Fertigstellungspflege kann kein Betrieb ' +
'eine Gewährleistung auf das Anwachsen und Gedeihen der Pflanzen geben. Wird die ' +
'Pflanzung ohne Fertigstellungspflege beauftragt, kann lediglich nachgewiesen werden, dass ' +
'die entsprechende Anzahl der richtigen Pflanzen in der vereinbarten Qualität so eingebracht ' +
'wurden, wie es zuvor verabredet wurde. Eine Gewährleistung für das Anwachsen der ' +
'Pflanzen bzw. für das Angehen der Ansaat besteht dann nicht. <br><br> ' +
'Die Fertigstellungspflege wird durch die DIN 18916 geregelt und ist Bestandteil der Pflanzung. <br><br> ' +
'Je nach Jahreszeit, Standort und Pflanzenauswahl sind verschiedene Maßnahmen während ' +
'der Fertigstellungspflege notwendig, um den abnahmefähigen Zustand zu erreichen. Hierzu zählen: <br><br>' +
'Pflanzungen:<br>' +
'- Lockern, Säubern und Ausmähen der Pflanzungen, Entfernen von Wildwuchs und Unkräutern <br>' +
'- Überprüfen der Pflanzenverankerungen <br>' +
'- Entfernen vertrockneter und beschädigter Pflanzenteile <br>' +
'- Düngen der Flächen <br> ' + 
'- Wässern der Pflanzen <br><br>' +
'Rasenanlagen:<br>'+
'- Wässern der Rasenflächen<br>'+
'- Düngen <br>' +
'- Mähen (der abnahmefähige Zustand der Rasenflächen ist je nach Rasentyp nach 4 ' +
'bis 6 Mähgängen erreicht, bei Landschaftsrasen nur ein Mähgang) <br>' +
'- Bekämpfung von unerwünschtem Aufwuchs <br><br>'+
'Die Leistungen der Fertigstellungspflege sind keine Nebenleistungen und sind entsprechend ' +
'gesondert zu vergüten. Der Auftraggeber kann entscheiden, die Pflege selbst auszuführen, ' +
'was allerdings zum Ausschluss der Gewährleistung führt. Möchten Sie, dass wir die ' +
'Fertigstellungspflege übernehmen, erstellen wir Ihnen gern ein Angebot.'


export default function Info(){
    const { label } = useWindowWidth();
    return(
        <>
            <UpperSpace />
            <div className={`informationen ${label}`}>
                <h1>Informationen</h1>
                <p className='subtext'>Hier finden sie nützliche Informationen von Ihrem Fachmann.</p>

                <div className={`info ${label}`}> 
                    <h2>Gartenarbeit steuerlich absetzen</h2>
                    <p>
                        Egal, ob Sie Hausbesitzer oder Mieter sind, in Auftrag gegebene Gartenarbeiten können
                        steuerlich geltend gemacht werden – unter bestimmten Bedingungen. Vermieter machen die
                        Ausgaben als Werbungskosten geltend (diese greifen auch bei Gartenarbeiten an
                        Ferienhäusern). <br /> <br />
                        Pro Jahr können Sie insgesamt bis zu 5200 Euro für Gartenarbeiten in der Steuererklärung
                        absetzen, dabei muss zwischen einer Handwerksleistung und einer haushaltsnahen
                        Dienstleistung unterschieden werden.
                    </p>
                    <InfoBox heading={'Handwerksleistungen'} text={txt1} />
                    <InfoBox heading={'Haushaltsnahe Dienstleistungen'} text={txt2} />

                    <h2>Gewährleistung und Fertigstellungspflege</h2>
                    <InfoBox heading={'Abnahme und Gewährleistung'} text={txt20} />
                    <InfoBox heading={'Abnahme von Pflanzungen und Ansaaten, Fertigstellungspflege'} text={txt21} />

                    <h2>Pflegetipps zur Pflege der Stauden und Gehölze nach der Pflanzung</h2>
                    <InfoBox heading={'Wässern'} text={txt16}/>
                    <InfoBox heading={'Düngen'} text={txt17}/>
                    <InfoBox heading={'Gehölzschnitt'} text={txt18}/>
                    <InfoBox heading={'Beikräuter'} text={txt19}/>

                    <h2>Pflegetipps zur Pflege von Rasenanlagen</h2>
                    <p>
                        Der landschaftsgärtnerischen Fachpraxis entsprechend haben wir die dazu erforderlichen
                        Bodenvorbereitungsarbeiten durchgeführt. Das Feinplanum wurde erstellt und im Anschluss
                        daran der Fertigrasen engfugig verlegt sowie gleichmäßig angedrückt bzw. das
                        Rasensaatgut gleichmäßig ausgebracht, flach eingearbeitet, angedrückt und gewässert. <br/><br/>
                        Damit sich daraus nun eine belastbare und fest mit der Vegetationsschicht verwurzelte
                        Rasendecke bzw. eine Rasendecke mit einem gleichmäßigen Bestand entwickeln kann, sind
                        zunächst noch verschiedene Leistungen der sogenannten Fertigstellungspflege ( nach DIN
                        18917 Vegetationstechnik im Landschaftsbau – Rasen und Saatarbeiten) erforderlich.
                        Diese wird üblicherweise - bei entsprechender Vereinbarung - durch uns bei der Herstellung
                        einer Rasenfläche durchgeführt.<br/>
                        Sie möchten die erforderlichen Einzelleistungen der Fertigstellungspflege selbst erbringen
                        und auf unsere fachkundige Hilfe verzichten? Dann bitten wir Sie um Beachtung der
                        nachfolgenden Pflegehinweise:
                    </p>
                    <h3>Fertigstellungspflege Saatrasen</h3>
                    <InfoBox heading='Allgemeines' text={txt3} />
                    <InfoBox heading='Wässern' text={txt4} />
                    <InfoBox heading='Rasen mähen' text={txt5} />
                    <InfoBox heading='Düngen' text={txt6} />
                    <h3>Fertigstellungspflege Fertigrasenflächen (Rollrasen)</h3>
                    <p>
                        Nach etwas 3 Wochen bei Einhaltung der Pflege, können sie den Fertigrasen nutzen.
                    </p>
                    <InfoBox heading='Wässern' text={txt7} />
                    <InfoBox heading='Rasen mähen' text={txt8} />
                    <h3>Entwicklungs- und Unterhaltungspflege</h3>
                    <p>
                        Die besten Voraussetzungen für eine schöne und nutzbare Rasenfläche sind nun
                        geschaffen. Der funktionsfähige Zustand, je nach Ihren individuellen Ansprüchen, wird durch
                        entsprechende regelmäßige Pflegemaßnahmen der Entwicklungs- und Unterhaltungspflege
                        erhalten. So haben Sie lange Freude an Ihrer Rasenfläche.
                        Gerne stehen wir Ihnen mit Informationen sowie fachkundiger Unterstützung bei der Pflege
                        Ihres Rasens zur Seite.
                    </p>
                    <InfoBox heading='Rasen mähen' text={txt9} />
                    <InfoBox heading='Wässern' text={txt10} />
                    <InfoBox heading='Düngen' text={txt11} />
                    <InfoBox heading='Vertikutieren' text={txt12} />
                    <InfoBox heading='Moos und Unkrautbeseitigung' text={txt13} />
                    <InfoBox heading='Laub' text={txt14} />
                    <InfoBox heading='Aerifizieren (Lüften), Schlitzen, Lockern' text={txt15} />
                </div>
                <UpperSpace />
            </div>
        
        </>
    )
}