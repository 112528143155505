import { Row, Col, Modal, Button, Carousel} from "react-bootstrap"
import React, { useState } from "react";
import { useWindowWidth } from '../../../App';

// space on top of the page
export function UpperSpace(){
    const { label } = useWindowWidth();

    return(
        <>
            <div className={`upperSpace ${label}`}></div>
        </>
    );
}

// simple component with heading, image and text
export function PuzImgTxt({heading, nohead, img, text, sub, left, pad}){
    const { label } = useWindowWidth();
    return(
        <>
            <div className={`PuzImgTxt ${label}`}>
                {!nohead ? (sub ? <h2 className={`${label}${left ? ' left' : ''}`}>{heading}</h2> : <h3 className={label}>{heading}</h3>) : null} 
                <Row className="w-100">
                    {left && 
                        <Col xs='12' lg='6' style={{margin: 0, padding:0}}>
                            <div className="imgspace" style={{backgroundImage: `url(${img})`}} />
                        </Col>
                    } 
                    <Col xs='12' lg='6' className={`d-flex align-items-center justify-content-center`} style={{margin: 0, padding:0}}>
                        <p className={`${left ? ' left' : ''}${sub ? ' sub' : ''}`} dangerouslySetInnerHTML={{__html: text}}></p>
                    </Col>
                    {!left &&
                        <Col xs='12' lg='6' style={{margin: 0, padding:0}}>
                             <div className="imgspace" style={{backgroundImage: `url(${img})`}} />
                        </Col>
                }
                </Row>
            </div>
        </>
    )
}

// simple component with heading, text, image and text
export function PuzTxtImgTxt({heading, img, text1, text2}){
    const { label, index } = useWindowWidth();
    return(
        <>
            <div className={`PuzImgTxt ${label}`}>
                <h3 className={label}>{heading}</h3>
                <Row className="w-100" >
                    <Col xs='12' lg='6' className="d-flex align-items-center justify-content-center" style={{margin: 0, padding: 0}}>
                        <p className={label} style={index === 2 ? {fontSize: "14pt"} : {}}dangerouslySetInnerHTML={{__html: text1}}></p>
                    </Col>
                    <Col xs='12' lg='6' style={{margin: 0, padding:0}}>
                        <div className="imgspace" style={{backgroundImage: `url(${img})`}} />
                    </Col>
                    <Col xs='12' className="d-flex align-items-center justify-content-center" style={{margin: 0, padding: 0}}>
                        <p className='txtimgtxt' dangerouslySetInnerHTML={{__html: text2}}></p>
                    </Col>
                </Row>
                
            </div>
        </>
    );
}

// Steckbrief, welcher eine Aufzählung aller Leistungen des Themenbereichs enthält
export function PuzSteckbrief({heading, leistungen, noLei, left}){
    const { label } = useWindowWidth();
    return(
        <>
            <div className={`PuzSteckbrief ${label}`}>
                <h3 className={label} style={left ? {textAlign: 'left', paddingLeft: '3%'} : {}}>{heading || "Unsere Leistungen im Überblick"}</h3>
                {!noLei && <ul className={label}>
                    {leistungen.map((leistung, i) => (
                        <li className={label} key={i}>{leistung}</li>
                    ))}
                </ul>}
            </div>
        </>
    );
}


// Img Gallery component with array imgs as props
export function PuzImgGallery({ imgs, head }) {
    const { label, index } = useWindowWidth();
    
    // zoomed image modal
    const [showModal, setShowModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const clickedImg = (e) => {
        return () => {
            setActiveIndex(e + page * numImages);
            toggleModal();
        };
    }

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    }

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // gallery
    const [page, setPage] = useState(0);
    const numImages = 6 + index * 3;
    const pageCount = Math.ceil(imgs.length / numImages);
    const startIndex = page * numImages;
    const endIndex = startIndex + numImages;
    const galleryImgs = imgs.slice(startIndex, endIndex);

    const handlePrevious = () => {
        if (page > 0) {
            if (page > pageCount) {
                setPage(pageCount);
            }
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        if (endIndex < imgs.length) {
            setPage(page + 1);
        }
    };

    
    return (
        <>
            <div className={`PuzImgGallery ${label} ${head ? 'head': ''}`}>
                {!head && <h3 className={label}>Galerie</h3>}
                <Row>
                    {galleryImgs.map((img, i) => (
                        <Col key={i} xs={6} md={4} xl={imgs.length === 3 ? 4 : 3} onClick={clickedImg(i)} >
                            <img src={img} alt="" />
                        </Col>
                    ))}
                </Row>
                {showModal && (
                    <Modal show={showModal} onHide={toggleModal} centered size="xl" >
                        <Modal.Body>
                            <Carousel data-bs-theme="dark" activeIndex={activeIndex} onSelect={handleSelect}>
                                {imgs.map((img, i) => (
                                    <Carousel.Item key={i}>
                                        <div className="d-flex justify-content-center w-100">
                                            <img alt='' className={`zoomedImg ${label}`}style={{backgroundImage: `url(${img})`}} />
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Modal.Body>
                    </Modal>
                )}
                {numImages < imgs.length && (
                    <Row>
                        <Col xs={2} className="offset-1 d-flex justify-content-center">
                            <Button onClick={handlePrevious} variant={page > 0 ? 'success' : 'light'} className="float-left">&lt;</Button>
                        </Col>
                        <Col xs={2} className="offset-2 d-flex justify-content-center">{page + 1}</Col>
                        <Col xs={2} className="offset-2 d-flex justify-content-center">
                            <Button onClick={handleNext} variant={endIndex < imgs.length ? 'success' : 'light'} className="float-right">&gt;</Button>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    );
}

// InfoBox component with heading and text
export function InfoBox({ heading, text }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleInfo = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="infoBox">
                <h4 onClick={toggleInfo}>{heading} {isOpen ? <i className="bi bi-arrow-up-short"></i> : <i className="bi bi-arrow-down-short"></i>}</h4>
                {isOpen && <p dangerouslySetInnerHTML={{__html: text}}></p>}
            </div>
        </>
    );
}




