// css:
import './../css/con.css';
import './../css/allserv.css';

// images:
import img1 from './../img/Mitarbeiter/IMG-20240325-WA0001.jpg'

// others:
import { UpperSpace, PuzImgTxt } from './allserv/allserv';

function Kontakt(){
    return(
        <>
            <UpperSpace />
            <div className="kontakt">
                <h1>Kontakt</h1>
                <PuzImgTxt 
                    img={img1} 
                    text={'Sie haben Interesse an unseren Dienstleistungen, Rückfragen oder wünschen eine Beratung? <br/> Dann rufen Sie uns jetzt an oder schreiben Sie uns eine E-Mail.<br/> Unsere Mitarbeiter stehen Ihnen gerne zur Verfügung. ' } 
                    left={true} 
                />
            </div>
        </>
    );
}

export default Kontakt;