// base
import { PuzImgTxt, UpperSpace, PuzSteckbrief, PuzImgGallery} from "./allserv";
import img from "./../../img/Plan.png";

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images1 = importAllImages(require.context("./../../img/3aPlanung/Wutha", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images2 = importAllImages(require.context("./../../img/3aPlanung/Schnellmannshausen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images3 = importAllImages(require.context("./../../img/3aPlanung/Grebensdorf", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images4 = importAllImages(require.context("./../../img/3aPlanung/Ershausen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images5 = importAllImages(require.context("./../../img/3aPlanung/SM2", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images6 = importAllImages(require.context("./../../img/3aPlanung/Hübner", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images7 = importAllImages(require.context("./../../img/3aPlanung/Jakobi", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const txt1=
"Gemeinsam mit Ihnen entwickeln wir ein stimmiges Gesamtkonzept für Ihren Garten oder Ihre Grünanlage. "+ 
'Mit einer ausführlichen Planung und Beratung verhelfen wir zu Ihrem individuellen Wohlfühlort. ' + 
'Durch Absprachen mit Lieferanten und Subunternehmen können wir Ihnen ein umfassendes und detailiertes Angebot mit möglichst realistischen Preisen erstellen.'

const leistungen1 = [
    "Neugestaltung einer sehr steilen und schrägen Zufahrt zum Grundstück",
    "Einbau von Betonwinkelstützen als Stützmauer",
    "Verlegen von wasserdurchlässigem Ökopflaster in der Zufahrt und vorhandenem Betonpflaster im Weg"
]

const leistungen2 = [
    "Verlegung von Betonpflaster und Stufen",
    "Einbau von Mauersteinen als Treppenwangen (Kundenmaterial)"
]

const leistungen3 = [
    "Terrassierung des Hanggrundstücks mit Betonwinkelstützen, Palisaden und verschiedenen Treppenanlagen",
    "Herstellung einer Fläche für Solarfelder",
    "Herstellung eines Koiteichs, Technik und Folienverlegung durch Subunternehmer",
    "Gestaltung verschiedener Terrassenbereiche mit Zuwegungen",
    "Einbau von zwei Wasserbecken",
    "Bepflanzung"
]

const leistungen4 = [
    "Abpflanzung zur Straße mit niedrigen Blütensträuchern und Bodendeckern"
]

const leistungen5 = [
    "Aufnehmen und Lagern von vorhandenem Granitpflaster, Mischen mit neuem Pflaster und anschließende Wiederverlegung",
    "Verlegung von Muschelkalk-Wildpflaster in den Randbereichen",
    "Abbruch und Verfüllung der Klärgrube",
    "Erneuerung der Abwasser- und Regenwasserleitungen auf dem Grundstück und Anschluss an das Trennsystem"
]

const leistungen6 = [
    "Neuanlegen von Granit-, Klinkerwegen und -pflasterflächen",
    "kreisförmige Anordnung von Kiesbeet, Pflanzstreifen und Rasenflächen",
    "Einbau eines Quellsteinbrunnens, einschl. Becken und Technik",
    "Bepflanzung"
]

const leistungen7 = [
    "Einfassung der Terrassen- und Wegeflächen mit einer Alukante",
    "Verlegung von großformatigen Keramikplatten auf Drainbeton mit Haftvermittler, einschl. fester Verfugung",
    "Einbau von Schlitzrinnen für die Entwässerung der Innenhoffläche",
    "Installation eines Wasserbeckens"
]

// website Gartenplanung
export default function GP () {
    return(
        <>
            <UpperSpace />
            <PuzImgTxt 
                heading="Gartenplanung"
                img={img}
                text={txt1}
            />
            <UpperSpace/>
            <PuzSteckbrief heading="Planung Gartenbereich Erfurt" leistungen={leistungen6} />
            <PuzImgGallery imgs={images6} head/>
            <UpperSpace/>

            <PuzSteckbrief heading="Planung kompletter Außenanlage in Grebendorf" leistungen={leistungen3} />
            <PuzImgGallery imgs={images3} head/>
            <UpperSpace/>

            <PuzSteckbrief heading="Planung Terrasse Bickenriede" leistungen={leistungen7} />
            <PuzImgGallery imgs={images7} head/>
            <UpperSpace/>

            <PuzSteckbrief heading="Planung Zufahrt Schnellmannshausen" leistungen={leistungen5} />
            <PuzImgGallery imgs={images5} head/>
            <UpperSpace/>

            <PuzSteckbrief heading="Planung in Ershausen" leistungen={leistungen4} />
            <PuzImgGallery imgs={images4} head/>
            <UpperSpace/>

            <PuzSteckbrief heading="Planung Grundstückszufahrt Wutha-Farnroda" leistungen={leistungen1} />
            <PuzImgGallery imgs={images1} head/>
            <UpperSpace/>

            <PuzSteckbrief heading="Planung Garagenzufahrt und Zuwegung zum Haus, Schnellmannshausen" leistungen={leistungen2} />
            <PuzImgGallery imgs={images2} head/>
            <UpperSpace/>
            
        </>
    );
}