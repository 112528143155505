// css:
import './../css/ref.css';
import './../css/allserv.css';

// images:

// others:
import { Button, Row } from 'react-bootstrap';
import { useWindowWidth } from '../../App';
import { UpperSpace, PuzSteckbrief, PuzImgGallery } from './allserv/allserv';
import { useState } from 'react';

const importAllImages = (r) => {
    return r.keys().map(r);
};

// descriptions
const leistungen1 = [
    'komplette Platzumgestaltung mit Treppenanlagen, Winkelstützen mit Sitzflächen, großen Pflasterflächen, Wasserspiel',
    'Sanierung von Natursteinmauern, Geländer, Pflanzarbeiten'
]

const leiHeil = [
    'Umgestaltung des Rosengartens am Theodor-Storm-Museum'
]

const leistungen2 = [
    'Pflasterarbeiten, Palisaden, wassergebundene Wegedecken, Pflanzarbeiten'
]

const leiMack = [
    'Mauerarbeiten, Errichtung von Treppenanlagen'
]

const leistungen3 = [
    'großflächige Natursteinpflasterarbeiten, Straßenentwässerung, Gestaltung einer Freifläche mit Sitzblöcken'
]

const leiLauch = [
    'Pflasterarbeiten Hauptweg, Anlage eines Aufenthaltsbereiches mit Wasserstelle und Sitzblöcken'
]
const leistungen4 = [
    'Herstellung einer gepflasterten Zufahrtsstraße, Erneuerung der Hauseingänge',
    '550m² Betonpflasterfläche, Sandsteinmauerwerk'
]
const leistungen5 = [
    'Herstellung der Außenanlagen am Begegnungsanger in Epterode',
    'Errichten einer Feuerstelle mit Rundbank'
]
const leistungen6 = [
    'Herstellung einer Freifläche und des Zugangs zum Bürgerhaus',
    'Umgestaltung des Spielplatzes mit Rutschenhügel und kleinem Amphitheater',
]

const leiBurg = [
    'Herstellung des Zugangs zum Burginneren, Errichtung eines Rundbogendurchgangs',
    'Errichten eines Fundamentes für einen Fahrstuhl als Vorbereitung für den barrierefreien Zugang der Burg, einschließlich aufwendiger Schalungs- und Bewehrungsarbeiten'
]

const leiBrücke = [
    'Erneuerung der Fußgängerbrücke über den Holunderbach',
    'Herstellung der Brückenfundamente, einschließlich Bewährungs- und Schalungsarbeiten, mit Jahreszahl',
    'Lieferung und Montage der Holzbrücke durch unseren Subunternehmer'
]

const leiSenior = [
    'Gestaltung der Außenanlagen an der Pflegestation mit barrierefreier Zuwegung, Hochbeeten und ansprechender Bepflanzung'
]

const leiFried = [
    'Anlegen von Grabfeldern für Urnenbestattungen mit Travertinplatten',
    'Bau von wassergebundenen Wegedecken, Bepflanzung und Verlegen von Sedummatten auf den Urnenfeldern'
]

const leiFisch = [
    'Errichtung einer Fischaufstiegsanlage am Heldrabach zur Wiederherstellung der Durchgängigkeit für Fische',
]

const leistungen10 = [
    'Errichtung einer barrierefreien Zuwegung mit Rampen und Ruhezonen',
    'Mauerbau mit Stützwinkeln und Natursteinen',
    'Lieferung und Montage der Absturzsicherung und des Geländers durch unseren Subunternehmer'
]

const leiGrund = [
    'Schulhofsanierung, komplette Umgestaltung der Außenanlage',
    'Bau eines Amphitheaters, Bolzplatzes, Spielplatzes, verschiedener Wegeverbindungen und Sitzmöglichkeiten',
    'Errichtung eines Schulgartens mit Hochbeeten'
]

const leiFried2 = [
    'Herstellung einer Zuwegung und des Aufenthaltsbereiches um die Trauerhalle',
    'Errichtung von Sitzbänken aus Natursteinblöcken und Holzauflage',
    'Anlage von Entwässerungseinrichtungen'
]

const leiFried3 = [
    'Erweiterung der Grabfeldfläche für Urnenbestattungen mit Travertinplatten und wassergebundenen Wegedecken',
]

const leiSozi = [
    'Erweiterung des vorhandenen Spielplatzes in der Jahnstraße',
    'Errichtung einer Kletterwand aus Stützwinkeln, einer Spielkombination mit Rutsche, Wackelbrücke und Kriechröhre',
    'Installation eines fugenlosen Fallschutzbelages in mehreren Farben'
]

const leiLauter = [
    'Friedhofsgestaltung, Anlegen von neuen Grabfeldern durch Terrassierung des Geländes mittels Betonsteinmauern'
]

const leiWies = [
    'Erneuerung der Freifläche mit Natursteinmauer, Klinkerpflasterfläche und neuer Bepflanzung'
]

const leiTGS = [
    'Schulhofsanierung: kompletter Abbruch der alten Betonflächen und Neugestaltung der Außenanlagen',
    'Anlage von verschiedenen Aufenthalts- und Spielbereichen, grünen Klassenzimmern, Wegeverbindungen und Einzäunung mit Toranlagen',
    'Errichten eines Spielfeldes für Fußball, Handball, Basketball mit Ballfangzaun',
    'Großflächige, abwechslungsreiche Bepflanzung mit Bäumen, Sträuchern, Hecken und Staudenflächen',
    'Lieferung und Montage von modernen Ausstattungsgegenständen z.B. Fertiggarage, Edelstahlhalbkugel, Trampolin und Halbschalensitzbänken'
]

const leiRöhr = [
    'Neugestaltung der Freianlage am Kindergarten mit Zufahrt und großzügiger Treppenanlage mit Stützwinkelwangen',
    'Lieferung und Montage von neuen Spielgeräten'
]

const leiKlein = [
    'Lieferung und Montage von Spielgeräten für Kleinkinder',
    'Herstellung von Fallschutzflächen mit Sand'
]

const leiKath = [
    'Errichtung einer Treppenanlage mit Natursteinwange',
    'Herstellung einer Stützwand aus Stützwinkeln mit Vormauerschale und Abdeckplatten'
]

const leilieth = [
    'Herstellung von zwei Holztreppen, Montage von Spielgeräten und Sitzmöglichkeiten',
    'Hangsicherung mit Jutegewebe, großflächige Bepflanzung und Ansaat von Wiesen und Rasen'
]

const leiGuts = [
    'Freiflächengestaltung, Herstellung eines Rundweges mit Natursteinläufer und wassergebundener Wegedecke',
    'Errichtung von Holzstegen, Teichmönch und Grillkota',
    'Bepflanzung mit einheimischen Gehölzen'
]

const leiklo = [
    'Neugestaltung des Hauptweges zum Kloster mit verschiedenen Stufenanlagen, Pflasterflächen und Handlauf'
]

const leianger =[
    'Neugestaltung der Freifläche mit einem Rundweg, verschiedenen Sitzmöglichkeiten',
    'Bepflanzung und Neuanlage von Rasenflächen'
]

const leilengen = [
    'Errichtung eines Amphitheaters aus Natursteinblöcken und einer Holzterrasse auf der ehemaligen Klärgrube',
    'Lieferung und Montage von Spielgeräten'
]

const leigeh = [
    'Herstellung der Brückenfundamente für die Fußgängerbrücke',
    'Natursteinpflasterarbeiten mit gesägtem Granit-Reihenpflaster und Entwässerungsrinne aus Basaltpflaster'
]

const leiStue = [
    'Sanierung der vorhandenen Betonsteinmauer, rückwärtige Stabilisierung mit Ischebeck-Ankern',
    'Vorschale aus Beton, Pflasterarbeiten'
]

const leiDuder = [
    'Herstellung eines Parkplatzes mit Zufahrt und Zuwegung, Entwässerungsrinnen und Bepflanzung',
    'Anlage eines Spielbereichs für Kleinkinder und eines Spielplatzes für Grundschüler mit verschiedenen Spielkombinationen',
    'Zaunbau mit verschiedenen Materialien'
]

const leiKerb = [
    'Herstellung der gesamten Freiflächen um das Kloster:',
    'Pflasterarbeiten für Platzgestaltung, Zufahrt und Zuwegung, Kanalbauarbeiten, Geländer, Ausstattungsgegenstände und Pflanzarbeiten',
    'Straßenbau / Asphaltarbeiten durch unseren Subunternehmer'
]

const leiWind = [
    'Gestaltung der Freifläche Windmühlenplatz:',
    'Große gepflasterte Platzfläche mit Wegeverbindungen aus wassergebundener Wegedecke, verschiedenen Sitzbereichen und Spielgeräten',
    'Individuell angefertigte Pergola durch unseren Subunternehmer, Rundbank, Bienenhotel'
]

const leiRust = [
    'Umgestaltung der Außenanlage, Herstellung einer Sitzstufenanlage, einer Motoriktreppe aus Robinienpfosten, verschiedene Sitzgelegenheiten',
    'Pflaster- und Pflanzarbeiten'
]

const leiBiene = [
    'Lieferung und Montage einer Spielkombination mit Fertigfundamenten',
    'Dacheindeckung durch unseren Subunternehmer'
]

const leiHild = [
    'Abbruch der alten Treppenanlage und der Betonplatten vor der Trauerhalle',
    'Errichtung der Treppenanlage mit gestrahlten Betonsteinstufen, Betonstein- und Natursteinpflasterarbeiten'
]

const leiFried4 = [
    'Erweiterung: Anlegen von Urnengrabfeldern mit Natursteinplatten, Wegen mit wassergebundener Wegedecke',
    'Pflanz- und Rasenarbeiten, Pflege'
]

const leiEsch = [
    'Errichtung eines Amphitheaters aus Sandsteinen, Spielplatzbau',
    'Herstellung verschiedener Sportflächen, Entwässerungsarbeiten, Zaunbau',
    'Pflasterarbeiten, Terrassen und Wege',
    'Rasen und Pflanzarbeiten'
]

const leiEis = [
    'Sanierung und Erneuerung der Zugänge und Kellertreppen',
    'Herstellung eines Plattenweges zur Verbindung der Eingänge',
    'Errichtung von Wäscheplätzen und eines Spielplatzes',
    'Rasenarbeiten'
]

const leiWick = [ 
    'Errichtung eines barrierefreien Zugangs, Herstellung von zwei Treppenanlagen mit Rampen',
    'Pflasterarbeiten'
]

const leiPar = [
    'Umgestaltung des Spielplatzes, Lieferung und Montage neuer Spielgeräte',
    'Herstellung der Wegeverbindungen mit wassegebundener Wegedecke, Bepflanzung'
]


const leiFried5 = [
    'Errichtung eines neuen Grabfeldes',
    'Natursteinmauerarbeiten und Treppenanlage',
    'Wegeverbindungen aus wassergebundener Wegedecke, Pflanz- und Rasenarbeiten'
]

const leiEsch2 = [
    'Erneuerung des Zuganges: Treppenanlage mit Rampe und Wangen aus Betonfertigteilen',
    'Pflasterarbeiten mit Entwässerungsrinne',
    'Pflanz- und Rasenarbeiten'
] 

const leiBer = [
    'Anlage eines neuen Fußweges am Hang mit 41 Treppenstufen',
    'Beleuchtung, Ausstattung, Rasen'
]

const leiStreg = [
    'Anlage eines Naturteiches mit Fußgängerbrücke und Bepflanzung'
]

const leiHis = [
    'Wiederherstellung des Mühlgrabengerinnes mit Granitformsteinen und Pflastersteinen',
    'Sanierung der vorhandenen Natursteinmauer, Einarbeitung des Treffurter Stadtwappens'
]

const leiWohn = [
    'Errichtung eines Parkplatzes für die Anwohner / Mieter der Wohnungsgenossenschaft',
    'Einbau von Stützwinkeln, Pflasterflächen mit Ökopflaster',
    'Feuerwehrzufahrt und Zuwegung'
]

const leiKita = [
    'Komplette Herstellung der Außenanlage des Kindergartens',
    'Asphaltierte Zufahrt durch unseren Subunternehmer',
    'Pflasterung der Parkflächen und Wege',
    'Errichtung der Zaunanlage mit Toren',
    'Anlage eines großzügigen Spielbereiches mit Spielgeräten, Sandkasten und Amphitheater',
    'Herstellung einer Regenwasser-Rückhalteanlage',
    'Errichtung einer Schwerlastmauer aus Sandsteinblöcken',
    'Bepflanzung und Rasenarbeiten'
]

const leiSoo = [
    'Herstellung der kompletten Außenanlage an der neugebauten Wohnstätte der Werraland Lebenswelten e.V.',
    'Errichtung der asphaltierten Zufahrt durch unseren Subunternehmer',
    'Pflasterung der Aufenthaltsbereiche, behindertengerechte Rampen und Wege, Treppenanlagen, Stützwinkelbau',
    'Lieferung und Montage einer Nestschaukel mit Fallschutzmatten',
    'Entwässerung, Beleuchtung, Zaun und Toranlagen, Bepflanzungen und Rasenarbeiten'
]

const leiESA = [
    'Umgestaltung der Außenanlage Grüne Höfe der AWG eG Eisenach',
    'Herstellung eines Pflasterweges, Errichtung eines Pavillions',
    'Lieferung und Montage von drei Cortenstahl-Hochbeeten und eines Sichtschutzzaunes durch unseren Subunternehmer',
]

const leistungen9 = [
    'Sanierung und Neugestaltung Außenanlagen und Vorplatz ',
    'Herstellung einer Treppenanlage und behindertengerechten Rampe',
]

const leistungen7 = [
    'Anlegung von Flachwasserteichen und Bepflanzung, als Ausgleichsmaßnahme',
    'Einbau von Bentonitplatten'
]
const leistungen8 = [
    'Umfeldgestaltung Platz Am Berg, Herstellung einer Zuwegung zur Quelle',
    'Natursteinmauerarbeiten und Verlegung von Natursteinplatten'
]

const leiErzberg = [
    'Herstellung von Wegeverbindungen und Aufenthaltsbereichen mit wassergebundener Wegedecke in einem Park an der Erzberger Allee',
    'Aufbau von individuell angefertigten Gabionenbänken',
    'Neuinstallation einer Wegbeleuchtung durch unseren Subunternehmer'
]

const leiBad = [
    'Abbruch der alten Spielgeräte, Zäune und Wege',
    'Erdarbeiten und Fallschutz für neue Spielkombinationen, Ausstattungsgegenstände',
    'Pflasterarbeiten, Geländemodellierung, Rasensaat'
]

const leiScherb = [
    'Rodungsarbeiten, Abbruch der alten Zaunanlage, Geländemodellierung',
    'Errichtung eines Holzlattenzaunes mit Metallpfosten, Bepflanzung, Rasensaat'
]

// import all folder images
const images1 = importAllImages(require.context("./../img/Referenzen/2005ESWPlatzderDtEinheit", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images2 = importAllImages(require.context("./../img/Referenzen/2006RosengartenHeiligenstadt", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images3 = importAllImages(require.context("./../img/Referenzen/2008TraumzielparkTreffurt", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images4 = importAllImages(require.context("./../img/Referenzen/2009FriedhofLauchröden", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images5 = importAllImages(require.context("./../img/Referenzen/2009GemeindeMackenrodeNatursteinmauer", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images6 = importAllImages(require.context("./../img/Referenzen/2009KuttelgasseSeidengasseMhlBasaltKalkstein", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images7 = importAllImages(require.context("./../img/Referenzen/2010BegegnungsangerEpterode", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images8 = importAllImages(require.context("./../img/Referenzen/2010ErzbergerAlleeBadSalzungen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images9 = importAllImages(require.context("./../img/Referenzen/2010TöpferhofGroßalmerode", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images10 = importAllImages(require.context("./../img/Referenzen/2013SpielplatzVölkershausen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images11 = importAllImages(require.context("./../img/Referenzen/2014AusgleichsmaßnahmeNiederhoneBentonit", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images12 = importAllImages(require.context("./../img/Referenzen/2014BrückeGroßburschla", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images13 = importAllImages(require.context("./../img/Referenzen/2014QuelleKörner", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images14 = importAllImages(require.context("./../img/Referenzen/2014KatholischeKircheWanfried", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images15 = importAllImages(require.context("./../img/Referenzen/2014BurgNormannsteinTreffurt", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images16 = importAllImages(require.context("./../img/Referenzen/2015BurgNormannsteinRampe", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images17 = importAllImages(require.context("./../img/Referenzen/2015FischtreppeHeldra", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images18 = importAllImages(require.context("./../img/Referenzen/2015FriedhofMihla", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images19 = importAllImages(require.context("./../img/Referenzen/2015SeniorenzentrumGöttingen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images20 = importAllImages(require.context("./../img/Referenzen/2016FriedhofMihla", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images21 = importAllImages(require.context("./../img/Referenzen/2016ThamsbrückGrundschule", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images22 = importAllImages(require.context("./../img/Referenzen/2017DiedorfWiesengrund", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images23 = importAllImages(require.context("./../img/Referenzen/2017FriedhofLauterbach", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images24 = importAllImages(require.context("./../img/Referenzen/2017SpielplatzSozZentrumLeinefelde", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images25 = importAllImages(require.context("./../img/Referenzen/2017FriedhofMihla", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images26 = importAllImages(require.context("./../img/Referenzen/2018KindergartenRöhrda", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images27 = importAllImages(require.context("./../img/Referenzen/2018TGSMenteroda", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images28 = importAllImages(require.context("./../img/Referenzen/2019AngerBerlingerode", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images29 = importAllImages(require.context("./../img/Referenzen/2019DingelstädtKlosterBA1", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images30 = importAllImages(require.context("./../img/Referenzen/2019GutsgartenRüdigershagen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images31 = importAllImages(require.context("./../img/Referenzen/2019HeiligenstadtLiethen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images32 = importAllImages(require.context("./../img/Referenzen/2019WDHKatharinenstieg", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images33 = importAllImages(require.context("./../img/Referenzen/2019SpielplatzHIGMengelröderWeg", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images34 = importAllImages(require.context("./../img/Referenzen/2020DingelstädtKerbscherBerg", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images35 = importAllImages(require.context("./../img/Referenzen/2020DuderstadtCampus", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images37 = importAllImages(require.context("./../img/Referenzen/2020HeyerodeStützmauer", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images38 = importAllImages(require.context("./../img/Referenzen/2020LengenfelduStGehwegbau", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images39 = importAllImages(require.context("./../img/Referenzen/2020StruthKIGA", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images40 = importAllImages(require.context("./../img/Referenzen/2020LengenfelduStKIGA", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images41 = importAllImages(require.context("./../img/Referenzen/2021ESARudolfBreitscheidStr", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images42 = importAllImages(require.context("./../img/Referenzen/2021ESWMüllersWeiden", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images43 = importAllImages(require.context("./../img/Referenzen/2021FriedhofMihla", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images44 = importAllImages(require.context("./../img/Referenzen/2021HildebrandshausenTreppe", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const images46 = importAllImages(require.context("./../img/Referenzen/2021MHLKitaBienenkörbchen", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images47 = importAllImages(require.context("./../img/Referenzen/2021RustenfeldeGSAußenanlage", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const images49 = importAllImages(require.context("./../img/Referenzen/2021BreitenbergWindmühlenplatz", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images50 = importAllImages(require.context("./../img/Referenzen/2022ASBStregdaTeich", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images51 = importAllImages(require.context("./../img/Referenzen/2022BernterodeKirche", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images52 = importAllImages(require.context("./../img/Referenzen/2022ESWGerberhaus", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images53 = importAllImages(require.context("./../img/Referenzen/2022MihlaFriedhof", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images54 = importAllImages(require.context("./../img/Referenzen/2022ParensenDGH", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images55 = importAllImages(require.context("./../img/Referenzen/2022WickenrodeKirche", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const images57 = importAllImages(require.context("./../img/Referenzen/2023AWGESA", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images58 = importAllImages(require.context("./../img/Referenzen/2023BSAWerraland", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images59 = importAllImages(require.context("./../img/Referenzen/2023HeLiKITA", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const images61 = importAllImages(require.context("./../img/Referenzen/2023HeyerodeWG", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const images63 = importAllImages(require.context("./../img/Referenzen/2023TreffurtMühlgraben", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

const images64 = importAllImages(require.context("./../img/Referenzen/2024BadSoodenAllendorf", true, /\.(png|jpe?g|JPG|svg)$/)).flat();
const images65 = importAllImages(require.context("./../img/Referenzen/2024Scherbda", true, /\.(png|jpe?g|JPG|svg)$/)).flat();


const sections = [
    {year: '2024', entries: [
        {heading: 'Spielplatz "Im Eilse" in Bad Sooden-Allendorf', leistungen: leiBad, imgs: images64},
        {heading: 'Friedhof in Scherbda', leistungen: leiScherb, imgs: images65}
    ]},
    {year: '2023', entries: [
        {heading: 'Grüne Höfe in Eisenach', leistungen: leiESA, imgs: images57},
        {heading: 'Balzerbornweg in Bad Sooden-Allendorf', leistungen: leiSoo, imgs: images58},
        {heading: 'AWO Kindertagesstätte "Bullerbü" in Hessisch Lichtenau', leistungen: leiKita, imgs: images59},
        {heading: 'Jägerstraße in Heyerode', leistungen: leiWohn, imgs: images61},
        {heading: 'Historischer Mühlgraben in Treffurt', leistungen: leiHis, imgs: images63},
    ]},
    {year: '2022', entries: [
        {heading: 'ASB Seniorenpflege "Haus am Alten See" in Stregda', leistungen: leiStreg, imgs: images50},
        {heading: 'Alte Kirche in Bernterode', leistungen: leiBer, imgs: images51},
        {heading: 'Gerberhaus in Eschwege', leistungen: leiEsch2, imgs: images52},
        {heading: 'Friedhof in Mihla', leistungen: leiFried5, imgs: images53},
        {heading: 'Dorfgemeinschaftshaus in Parensen', leistungen: leiPar, imgs: images54},
        {heading: 'Evangelische Kirche in Wickenrode', leistungen: leiWick, imgs: images55},
    ]},
    {year: '2021', entries: [
        {heading: 'Wohnblöcke in der "Rudolf-Breitscheid-Straße" in Eisenach', leistungen: leiEis, imgs: images41},
        {heading: 'Park- und Gartenanlage am Hotel "Müllers Weiden" in Eschwege', leistungen: leiEsch, imgs: images42},
        {heading: 'Friedhof in Mihla', leistungen: leiFried4, imgs: images43},
        {heading: 'Friedhof in Hildebrandshausen', leistungen: leiHild, imgs: images44},
        {heading: 'Kita "Bienenkörbchen" in Mühlhausen', leistungen: leiBiene, imgs: images46},
        {heading: 'Staatliche Grundschule "Am Rusteberg" in Rustenfelde', leistungen: leiRust, imgs: images47},
        {heading: 'Windmühlenplatz in Breitenberg', leistungen: leiWind, imgs: images49},
    ]},
    {year: '2020', entries: [
        {heading: 'Kloster Kerbscher Berg in Dingelstädt', leistungen: leiKerb, imgs: images34},
        {heading: 'Inklusiver Campus in Duderstadt', leistungen: leiDuder, imgs: images35},
        {heading: 'Stützmauer in Heyerode', leistungen: leiStue, imgs: images37},
        {heading: 'Gehwegbau am Gedeplatz in Lengenfeld unterm Stein', leistungen: leigeh, imgs: images38},
        {heading: 'Kindergarten "Ich bin Ich" in Struth', leistungen: leiStue, imgs: images39},
        {heading: 'Katholischer Kindergarten "St. Franziskus" in Lengenfeld unterm Stein', leistungen: leilengen, imgs: images40},
    ]},
    {year: '2019', entries: [
        {heading: 'Anger in Berlingerode', leistungen: leianger, imgs: images28},
        {heading: 'Kloster Kerbscher Berg in Dingelstädt', leistungen: leiklo, imgs: images29},
        {heading: 'Gutsgarten in Rüdigershagen', leistungen: leiGuts, imgs: images30},
        {heading: 'Freifläche "Auf den Liethen" in Heilbad Heiligenstadt', leistungen: leilieth, imgs: images31},
        {heading: 'Katharinenstieg in Wendehausen', leistungen: leiKath, imgs: images32},
        {heading: 'Kleinspielplatz "Auf dem Hohen Rott" in Heilbad Heiligenstadt', leistungen: leiKlein, imgs: images33}
    ]},
    {year: '2018', entries: [
        {heading: 'Kindergarten in Röhrda', leistungen: leiRöhr, imgs: images26},
        {heading: 'Integrationscampus an der TGS Menteroda', leistungen: leiTGS, imgs: images27}
    ]},
    {year: '2017', entries: [
        {heading: 'Wiesengrund in Diedorf', leistungen: leiWies, imgs: images22},
        {heading: 'Friedhof in Lauterbach', leistungen: leiLauter, imgs: images23},
        {heading: 'Spielplatz am Sozialen Zentrum in Leinefelde', leistungen: leiSozi, imgs: images24},
        {heading: 'Friedhof in Mihla', leistungen: leiFried3, imgs: images25}
    ]},
    {year: '2016', entries: [
        {heading: 'Friedhof in Mihla', leistungen: leiFried2, imgs: images20},
        {heading: 'Staatliche Grundschule in Thamsbrück', leistungen: leiGrund, imgs: images21}
    ]},
    {year: '2015', entries: [
        {heading: 'Burg Normannstein in Treffurt', leistungen: leistungen10, imgs: images16},
        {heading: 'Fischtrepp in Heldra', leistungen: leiFisch, imgs: images17},
        {heading: 'Friedhof in Mihla', leistungen: leiFried, imgs: images18},
        {heading: 'Seniorenzentrum in Göttingen', leistungen: leiSenior, imgs: images19}
    ]},
    {year: '2014', entries: [
        {heading: 'Flachwasserteiche in Eschwege / Niederhone', leistungen: leistungen7, imgs: images11},
        {heading: 'Brücke in Großburschla', leistungen: leiBrücke, imgs: images12},
        {heading: 'Platz "Am Berg" in Körner', leistungen: leistungen8, imgs: images13},
        {heading: 'Katholische Kirche in Wanfried', leistungen: leistungen9, imgs: images14}, // todo
        {heading: 'Burg Normannstein in Treffurt', leistungen: leiBurg, imgs: images15}
    ]},
    {year: '2013', entries: [
        {heading: 'Bürgerhaus und Spielplatz in Völkershausen', leistungen: leistungen6, imgs: images10}
    ]},
    {year: '2010', entries: [
        {heading: 'Begegnungsanger in Großalmerode / Stt. Epterode', leistungen: leistungen5, imgs: images7},
        {heading: 'Erzberger Allee in Bad Salzungen', leistungen: leiErzberg, imgs: images8},
        {heading: 'Töpferhof in Großalmerode', leistungen: leistungen4, imgs: images9}
    ]},
    {year: '2009', entries: [
        {heading: 'Friedhof in Lauchröden', leistungen: leiLauch, imgs: images4}, 
        {heading: 'Gerberviertel in Mühlhausen', leistungen: leistungen3, imgs: images6},
        {heading: 'Dorfgestaltung in Mackenrode', leistungen: leiMack, imgs: images5} 
    ]},
    {year: '2008', entries: [
        {heading: 'Traumzielpark in Treffurt', leistungen: leistungen2, imgs: images3}
    ]},
    {year: '2006', entries: [
        {heading: 'Rosengarten in Heilbad Heiligenstadt', leistungen: leiHeil, imgs: images2}
    ]},
    {year: '2005', entries: [
        {heading: 'Platz der Deutschen Einheit in Eschwege', leistungen: leistungen1, imgs: images1}
    ]}
]


function Referenzen(){

    const { label } = useWindowWidth();
    const [currentYearIndex, setCurrentYearIndex] = useState(0);

    const handlePreviousYear = () => {
        if (currentYearIndex > 0) {
            setCurrentYearIndex(currentYearIndex - 1);
        }
        else {
            setCurrentYearIndex(sections.length - 1);
        }
    };

    const handleNextYear = () => {
        if (currentYearIndex < sections.length - 1) {
            setCurrentYearIndex(currentYearIndex + 1);
        }
        else {
            setCurrentYearIndex(0);
        }
    };


    function handleClick(index) {
        setCurrentYearIndex(index);
    }

    const currentYear = sections[currentYearIndex].year;

    return(
        <>
            <UpperSpace/>
                <div className='referenzen'>
                    <h1>Referenzen</h1>
                    <p style={{marginTop: '40px'}}>Sie sind an einem bestimmten Jahr interessiert? Dann wählen Sie es hier aus:</p>
                    <div className="centered-button-list">
                        {sections.map((section, index) => (
                                <Button variant='success' className={label} key={index} onClick={() => handleClick(index)}>
                                    {section.year}
                                </Button>
                        ))}
                    </div>
                    <UpperSpace />
                    <div className="centered-button-list" style={{borderTop: '2px solid #185C27', borderBottom: '2px solid #185C27'}}>
                        <Button variant='success' style={{marginTop: '20px'}} onClick={handlePreviousYear}>
                            {'<'}
                        </Button>
                        <h2>{currentYear}</h2>
                        <Button variant='success' style={{marginTop: '20px'}}  onClick={handleNextYear}>
                            {'>'}
                        </Button>
                    </div>
                    <UpperSpace />
                    <Row>
                        {sections[currentYearIndex].entries.map((entry, entryIndex) => (
                            <Row key={entryIndex}>
                                <div>
                                    <PuzSteckbrief heading={entry.heading} leistungen={entry.leistungen} />
                                    <PuzImgGallery imgs={entry.imgs} head />
                                    <UpperSpace />
                                </div>
                            </Row>
                        ))}
                    </Row>
                </div>
        </>
    );
}

export default Referenzen;