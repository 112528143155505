// css:
import './../css/services.css';

// images:
import GP from "./../img/Plan.png";
import EA from "./../img/3bErdarbeitenundEntwässerung/Erdarbeiten/Erdarbeiten_30.JPG";
import WP from "./../img/3cWegegestaltungundPflasterbau/Eingänge/Eingänge_80.JPG";
import ZM from "./../img/3dZäuneundMauern/Zaunbau/Zaunbau_060.jpg";
import RB from "./../img/3eRasenundBepflanzung/Rollrasen/Rollrasen_50.jpg"
import GL from "./../img/3fGarten-undLandschaftspflege/Pflege/Pflege_10.jpg";
import WA from './../img/3gWasseranlagen/Schwimmteichbau/Schwimmteichbau_20.JPG';
import SO from "./../img/3hSonstiges/Spielplatzbau/Spielplatzbau_050.JPG";

// others:
import { Row, Col } from 'react-bootstrap';
import { useWindowWidth } from '../../App';


// data for the list
const Schwerpunkte = [
    {
        title:'Gartenplanung',
        img: GP,
        href: '/Leistungen/Planung'
    },
    {
        title:'Erdarbeiten und Entwässerung',
        img: EA,
        href: '/Leistungen/ErdarbeitenEntwässerung'
    },
    {
        title:'Wege- und Platzgestaltung, Terrassen',
        img: WP,
        href: '/Leistungen/WegePlatzTerassen'
    },
    {
        title:'Zäune und Mauern',
        img: ZM,
        href: '/Leistungen/ZäuneMauern'
    },
    {
        title:'Rasen und Bepflanzungen',
        img: RB,
        href: '/Leistungen/RasenBepflanzungen'
    },
    {
        title:'Garten- und Landschaftspflege',
        img: GL,
        href: '/Leistungen/GartenLandschaftspflege'
    },
    {
        title:'Wasseranlagen',
        img: WA,
        href: '/Leistungen/Wasseranlagen'
    },
    {
        title:'Sonstiges',
        img: SO,
        href: '/Leistungen/Sonstiges'
    }
];

// website leistungen
function Leistungen(){
    const { label } = useWindowWidth();
    return(
        <>
            <div className={`center ${label}`}>
                <Row>
                    <Col>
                        <h3>Leistungen</h3>
                        <p>
                            Bei uns erhalten Sie professionelle Unterstützung und fachliches Know-how für alle Phasen ihres Projektes.
                            Von der Beratung, kreativen Planung, fachlich kompetenter Ausführung, bis zur Pfelge, wir kümmern uns um Ihr Anliegen.
                            <br />
                            Mit unserer langjährigen Erfahrung und dem besonderen Blick entwickeln wir kreative Lösungen für den modernen und zeitgemäßen
                            Garten- und Landschaftsbau. 
                            Zusammen mit Ihnen gestalten wir gemeinsam Ihren neuen Lebensraum ganz nach Ihren individuellen Vorstellungen
                            und unserem Ideenreichtum. Wir sind sowohl für Privat- und Geschäftskunden als auch für Ämter öffentliche Einrichtungen, 
                            Vereine und Kirchengemeinden die richtigen Ansprechpartner, wenn es um professionellen Garten- und Landschaftsbau geht.
                            <br />
                            Unsere Arbeit entspricht stets höchsten Qualitätsansprüchen. Wir bieten eine große Vielfalt an Leistungen.
                        </p>
                        <h3>Unsere Leistungsschwerpunkte: </h3>
                    </Col>
                </Row>
            </div>
            <Row style={{width: '100%', margin: '0 0 100px 0', padding: 0}}>
                <Col>
                    <div className={`rowConst ${label}`}>
                        <RowConstruct />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Leistungen;


function RowConstruct(){
    
    const handleClick = (url) => {
        window.location.href = window.location.origin + url;
    };
    
    return(
        <>
            <div className="centered">
                {Schwerpunkte.map((item) => (
                    <Row onClick={() => handleClick(item.href)} className={`list backg fadeInUpVisible`} style={{backgroundImage: `url(${item.img})`}}>
                        <Col>
                            <h3>{item.title}</h3>
                        </Col>
                    </Row>
                ))}
            </div>
        </>
    );
}