// css:
import './../css/home.css';

// images:
import GP from "./../img/Plan.png";
import EA from "./../img/3bErdarbeitenundEntwässerung/Erdarbeiten/Erdarbeiten_30.JPG";
import WP from "./../img/3cWegegestaltungundPflasterbau/Eingänge/Eingänge_80.JPG";
import ZM from "./../img/3dZäuneundMauern/Zaunbau/Zaunbau_060.jpg";
import RB from "./../img/3eRasenundBepflanzung/Rollrasen/Rollrasen_50.jpg"
import GL from "./../img/3fGarten-undLandschaftspflege/Pflege/Pflege_10.jpg";
import WA from './../img/3gWasseranlagen/Schwimmteichbau/Schwimmteichbau_20.JPG';
import SO from "./../img/3hSonstiges/Spielplatzbau/Spielplatzbau_050.JPG";


// others:
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container, Row, Col, Carousel } from 'react-bootstrap';
import { useWindowWidth } from '../../App';
import { Card, Stack } from 'react-bootstrap';
import { UpperSpace } from './allserv/allserv';
import { useState } from 'react';

// functions:
const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

const carouselLeistungen = [
    {
        title:'Gartenplanung',
        text: 'Die Gestaltung der Gartens...',
        img: GP,
        href: '/Leistungen/Planung'
    },
    {
        title:'Erdarbeiten und Entwässerung',
        text: '',
        img: EA,
        href: '/Leistungen/ErdarbeitenEntwässerung'
    },
    {
        title:'Wege- und Platzgestaltung, Terrassen',
        text: '',
        img: WP,
        href: '/Leistungen/WegePlatzTerassen'
    },
    {
        title:'Zäune und Mauern',
        text: '',
        img: ZM,
        href: '/Leistungen/ZäuneMauern'
    },
    {
        title:'Rasen und Bepflanzungen',
        text: '',
        img: RB,
        href: '/Leistungen/RasenBepflanzungen'
    },
    {
        title:'Garten- und Landschaftspflege',
        text: '',
        img: GL,
        href: '/Leistungen/GartenLandschaftspflege'
    },
    {
        title:'Wasseranlagen',
        text: '',
        img: WA,
        href: '/Leistungen/Wasseranlagen'
    },
    {
        title:'Sonstiges',
        text: '',
        img: SO,
        href: '/Leistungen/Sonstiges'
    }
];

export default function Home(){
    const { label, index } = useWindowWidth();
    const [isIOSDevice] = useState(isIOS());

    return (
        <main className={`home ${label}`} >
            <div className={`background ${label}`}  style={isIOSDevice && (index !== 2) ? {} : {backgroundAttachment: 'fixed'}}>
                <div className='titleback'>
                    <h1 className={`title ${label}`}>Garten-, Landschafts- & Pflasterbau</h1>
                </div>
            </div>
            <Cards />
            <Preview />
            <UpperSpace />
            <h1 className='headLeist'>Unsere Leistungen im Überblick</h1>
            <MultiItemSlider />
            <UpperSpace />
        </main>
    );
}

function Cards(){
    const { label, index } = useWindowWidth();
    const butSize = ['sm', 'lg', 'lg'];

    return(
        <>
        <div className={`fullbox ${label}`}>
            <Container className={`size ${label}`}> 
                <Row className='row-height'>
                    <Col>
                        <div className={`Performances heights ${label}`}>
                            <Row className='row-height' style={{paddingTop: 0}}>
                                <Col xs={12} sm={9} md={8} lg={6} style={{padding:0}}>
                                    <h1 className={`Banner  ${label}`}>Leistungen</h1>
                                </Col>
                                <Col xs={1} className='offset-9 offset-sm-10 offset-xl-11'>
                                    <Button size={butSize[index]} href="/Leistungen" variant="light" style={label === 'desk' ? {} : {marginLeft : '20px'}} className={`myBut ${label}`}>&gt;</Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row style={{paddingTop: '10px'}}>
                            <Col style={{padding:0}}>
                                <div className={`AboutUs heights ${label}`}>
                                    <Row style={{paddingTop: '0px'}}>
                                        <Col  xs={12} sm={9} md={9} lg={8} style={{padding:0}}>
                                            <h1 className={`Banner ${label}`}>Über uns</h1>
                                        </Col>
                                        <Col xs={1} className='offset-8 offset-sm-9 offset-xl-10'>
                                            <Button size={butSize[index]} href="/ÜberUns" variant="light" className={`myBut ${label}`}>&gt;</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '20px', paddingTop: 0}}>
                            <Col style={{padding:0}}>
                                <div className={`Jobs heights ${label}`}>
                                    <Col>
                                        <Row style={{paddingTop: 0}}>
                                            <Col xs={12} sm={9} md={9} lg={8} style={{padding:0}}>
                                                <h1 className={`Banner ${label}`}>Referenzen</h1>
                                            </Col>
                                            <Col xs={1} className='offset-8 offset-sm-9 offset-xl-10'>
                                                <Button size={butSize[index]} href='/Referenzen' variant="light" className={`myBut ${label}`}>&gt;</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row className='h-100'>
                            <Col style={{padding:0}}>
                                <div className={`Refs ${label}`}>
                                    <Col>
                                        <Row style={{paddingTop: 0}}>
                                            <Col xs={11} sm={10} md={10} lg={8} style={{padding:0}}>
                                                <h1 className={`Banner ${label}`}>Jobs</h1>
                                            </Col>
                                            <Col xs={1} md={1} lg={1} className='offset-8 offset-sm-9 offset-xl-10'>
                                                <Button size={butSize[index]} href="/Jobs" variant="light" className={`myBut ${label}`}>&gt;</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{paddingTop: '20px'}}>
                    <Col>
                        <Button variant="success" size={butSize[index]} href="/Informationen" className='w-100 h-100' style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>Mehr Informationen</Button>
                    </Col>
                    <Col>
                        <Button variant="success" size={butSize[index]} href="/Kontakt" className='w-100 h-100' style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>Kontakt</Button>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
}

function Preview(){
    const { label, index } = useWindowWidth();
    const butSize = ['sm', 'lg', 'lg'];

    return(
        <>
            <div className={`prevAboutUs ${label}`}>
                <h1 className={`${label}`}>Über uns</h1>
                <p className={`${label}`}>Neugierig auf die Menschen hinter den Gärten? Erfahren Sie mehr über unser Team und unsere Geschichte!</p>
                <div className={`thePic ${label}`}></div>
                <div className="spaceforBut">
                    <Button size={butSize[index]} variant="success" href="/ÜberUns" className='prevBut'>Mehr erfahren</Button>
                </div>
            </div>
        </>
    );
}


const MultiItemSlider = () => {
    const { index } = useWindowWidth();
    const stacks = [];
    const RowSize = index + 1;
    const stackCount = Math.ceil(carouselLeistungen.length / RowSize);

    const handleClick = (url) => {
        window.location.href = window.location.origin + url;
    };

    for (let i = 0; i < stackCount; i++) {
        const startIndex = i * RowSize;
        const endIndex = startIndex + RowSize;
        const stackItems = carouselLeistungen.slice(startIndex, endIndex);
        const stack = (
            <Stack
                key={i}
                direction="horizontal"
                className="h-100 justify-content-center align-items-center"
                gap={index + 2}
                style={{margin: '0 0px 0 0px', width: '100%'}}
            >
                {stackItems.map((item, index) => (
                    <Card key={index} style={{ width: `${75/RowSize}%`}} className=''>
                        <Card.Img variant="top" src={item.img} height={`${RowSize * 80}px`} style={{width: '100%', overflow: 'hidden'}}/>
                        <Card.Body>
                            <Card.Title>{item.title}</Card.Title>
                            <Button variant="success" style={{marginTop: '25px'}} onClick={()=> handleClick(item.href)}>Zur Seite</Button>
                        </Card.Body>
                    </Card>
                ))}
            </Stack>
        );
        stacks.push(stack);
    }
    return (
        <>
            <Carousel variant="dark">
                {stacks.map((stack, index) => (
                    <Carousel.Item key={index} style={{ height: 500, width: '90%', marginLeft: '5%'}}>
                        {stack}
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
};

