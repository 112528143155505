//CSS
import './App.css';
import './components/css/innerNav.css';

// Components
import Home from './components/sides/home';
import Leistungen from './components/sides/services';
import Referenzen from './components/sides/ref';
import Überuns from './components/sides/au';
import Jobs from './components/sides/jobs';
import Kontakt from './components/sides/con';
import Impressum from './components/sides/imp';
import Header from './components/sides/header';
import Footer from './components/sides/footer';
import GP from './components/sides/allserv/GP';
import EA from './components/sides/allserv/EA';
import WP from './components/sides/allserv/WP';
import ZM from './components/sides/allserv/ZM';
import RB from './components/sides/allserv/RB';
import GL from './components/sides/allserv/GL';
import WA from './components/sides/allserv/WA';
import SO from './components/sides/allserv/SO.js';
import Info from './components/sides/info';

// other imports
import {BrowserRouter, Route, Routes} from "react-router-dom"
import { createContext, useContext, useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

// check if the size is a mobile, tab or desktop device
const WindowWidthContext = createContext({ index: 3, label: 'desktop' });

export const useWindowWidth = () => {
  return useContext(WindowWidthContext);
};

// 0 = mobile, 1 = tab, 2 = desk
const getSizeIndex = (width) => {
  if (width < 768) return 0;
  if (width >= 768 && width < 1200) return 1;
  return 2;
};

// labels[i] gives the correct css
const labels = ['mobile', 'tab', 'desk'];

// mainApp
export default function App() {
  const [sizeIndex, setSizeIndex] = useState(getSizeIndex(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setSizeIndex(getSizeIndex(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const value = {
    index: sizeIndex,
    label: labels[sizeIndex]
  };

  return (
    <>
      <WindowWidthContext.Provider value={value}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/ÜberUns" element={<Überuns/>} />
            <Route path="/Leistungen" element={<Leistungen/>} />
              <Route path="/Leistungen/Planung" element={<GP/>} />
              <Route path="/Leistungen/ErdarbeitenEntwässerung" element={<EA/>} />
              <Route path="/Leistungen/WegePlatzTerassen" element={<WP/>} />
              <Route path="/Leistungen/ZäuneMauern" element={<ZM/>} />
              <Route path="/Leistungen/RasenBepflanzungen" element={<RB/>} />
              <Route path="/Leistungen/GartenLandschaftspflege" element={<GL/>} />
              <Route path="/Leistungen/Wasseranlagen" element={<WA/>} />
              <Route path="/Leistungen/Sonstiges" element={<SO/>} />
            <Route path="/Referenzen" element={<Referenzen/>} />
            <Route path='/Informationen' element={<Info/>} />
            <Route path="/Jobs" element={<Jobs/>} />
            <Route path="/Kontakt" element={<Kontakt/>} />
            <Route path="/Impressum" element={<Impressum/>} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </WindowWidthContext.Provider>
    </>
  );
}




