// css:
import './../css/header.css';
import './../css/innerNav.css';

// images:
import logo from './../img/log_mid.png';

// others:
import { Offcanvas, NavDropdown, Navbar, Nav, Container, Button} from 'react-bootstrap';
import { useWindowWidth } from '../../App';
import React, {useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';


// the Header for all pages
function Header(){
    // change visibility of sticky Navbar when scrolling
    const[prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    // visibility of the header
    const[headerVis, setHeaderVis] = useState(true);
    // visibility of the nav
    const[navVis, setNavVis] = useState(false);

    const { label, index } = useWindowWidth();

    // if we scroll down, we want the header to disappear
    useEffect(() =>{
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrolledDown = prevScrollPos < currentScrollPos && (currentScrollPos/window.innerHeight > 0.74);

            setHeaderVis(!isScrolledDown);
            setPrevScrollPos(currentScrollPos);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    // toggle visibility when pressing the burger button
    const toggleNavVis = () => {
        setNavVis(!navVis);
    }

    return(
        // links Logo, dann Menü Button
        <>
        <header className={`navbar ${label} ${headerVis ? 'visible' : 'hidden'}`} style={{position: 'fixed', zIndex: 30}}>
            <div className="container-fluid" style={{position: 'fixed', zIndex: 30}}>
                <div className='col-3'>
                    <img className='imgs' src={logo} alt="Logo"/>
                </div>
                <div className={index === 2 ? 'col-9' : 'col-8'}>
                    {!(label === 'mobile' || label === 'tab') && (
                    <div className={`slide`}>
                        <InnerNav />
                    </div>)}
                </div>
                {(label === 'mobile' || label === 'tab') && (
                    <div className='col-1 right top'>
                        <button 
                            className="navbar-toggler" 
                            type="button"
                            data-bs-toggle="collapse" 
                            data-bs-target="#navbarToggleExternalContent" 
                            aria-controls="navbarToggleExternalContent" 
                            aria-expanded='false' 
                            aria-label="Toggle navigation"
                            onClick={toggleNavVis}
                        >
                            <span className={navVis ? '' : 'navbar-toggler-icon'} style={navVis ? {fontSize: '22pt'} : {}}>{navVis? ' X ' : ''}</span>
                        </button>   
                    </div>
                )}
            </div>
            {headerVis ? (label==='mobile' || label==='tab') && <OffcanvasNav /> : []}
        </header>
        </>
    )
}

export default Header;


// the InnerNav for Desktop users
function InnerNav(){
    return(
        <>
            <div className="dropNav">
                <div className='navigation'>
                    <Nav.Link href="/" className='linkit'>Home</Nav.Link>
                    <Nav.Link href="/ÜberUns" className='linkit'>Über uns</Nav.Link>
                    <div className='dropdown'>
                        <Button className='dropbtn' href='/Leistungen' variant="outline-success">Leistungen</Button>
                        <div className="dropdown-content">
                            <Nav.Link href="/Leistungen/Planung">Gartenplanung</Nav.Link>
                            <Nav.Link href="/Leistungen/ErdarbeitenEntwässerung">Erdarbeiten und Entwässerung</Nav.Link>
                            <Nav.Link href="/Leistungen/WegePlatzTerassen">Wege- und Platzgestaltung, Terrassen</Nav.Link>
                            <Nav.Link href="/Leistungen/ZäuneMauern">Zäune und Mauern</Nav.Link>
                            <Nav.Link href="/Leistungen/RasenBepflanzungen">Rasen und Bepflanzungen</Nav.Link>
                            <Nav.Link href="/Leistungen/GartenLandschaftspflege">Garten- und Landschaftspflege</Nav.Link>
                            <Nav.Link href="/Leistungen/Wasseranlagen">Wasseranlagen</Nav.Link>
                            <Nav.Link href="/Leistungen/Sonstiges">Sonstiges</Nav.Link>
                        </div>
                    </div>
                    <Nav.Link href="/Referenzen" className='linkit'>Referenzen</Nav.Link>
                    <Nav.Link href="/Informationen" className='linkit'>Informationen</Nav.Link>
                    <Nav.Link href="/Jobs" className='linkit'>Jobs</Nav.Link>
                    <Nav.Link href="/Kontakt" className='linkit'>Kontakt</Nav.Link>
                </div>
            </div>
        </>
    );
}


function OffcanvasNav() {
    const { label } = useWindowWidth();
    return (
    <>
      {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map((expand) => (
        <Navbar key={expand} expand={expand} className={`bg-body-white mb-3 ${label}`}>
          <Container fluid>
            <Navbar.Brand href="#" style={{paddingTop: '0px'}}><img className='imgs' src={logo} alt="Logo"/></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Gliederung
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3" >
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/ÜberUns">Über Uns</Nav.Link>
                    <Row>
                        <Col xs="1">
                            <NavDropdown
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                style={{width: '320px', position: 'relative', zIndex: 20}}
                            >
                                <NavDropdown.Item href="/Leistungen/Planung">Gartenplanung</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/ErdarbeitenEntwässerung">Erdarbeiten und Entwässerung</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/WegePlatzTerassen">Wege- und Platzgestaltung, Terrassen</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/ZäuneMauern">Zäune und Mauern</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/RasenBepflanzungen">Rasen und Bepflanzungen</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/GartenLandschaftspflege">Garten- und Landschaftspflege</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/Wasseranlagen">Wasseranlagen</NavDropdown.Item>
                                <NavDropdown.Item href="/Leistungen/Sonstiges">Sonstiges</NavDropdown.Item>
                            </NavDropdown>
                        </Col>
                        <Col style={{position: 'relative', zIndex:30, height: '20px'}}><Nav.Link href='/Leistungen'>Leistungen</Nav.Link></Col>
                    </Row>
                    <Nav.Link href="/Referenzen">Referenzen</Nav.Link>
                    <Nav.Link href="/Informationen">Informationen</Nav.Link>
                    <Nav.Link href="/Jobs">Jobs</Nav.Link>
                    <Nav.Link href="/Kontakt">Kontakt</Nav.Link>
                    <Nav.Link href="/Impressum" style={{borderTop: '1px solid black', marginTop: '20px'}}>Impressum</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}