// css:
import './../css/footer.css';

// images:
import pos from './../img/marker.png';
import tel from './../img/tel.png';
import mail from './../img/mail.png';

// others:
import { Row, Col, Nav } from 'react-bootstrap';
import { useWindowWidth } from '../../App';

// builds the footer of every side
function Footer(){
    const { label } = useWindowWidth();
    return(
        <>
            <footer className={`box ${label}`}>
                <Row>
                    <Col>
                        <h1 className={`cap ${label}`}>Stephan Goldmann GmbH</h1>
                    </Col>
                </Row>
                <Row className={`footbot ${label}`}>
                    <Col xs={6} md={4} className='col'>
                        <p className='heading'><img className="footimg" src={pos} alt="Position" /><span> Adresse</span></p>
                        <p className="txt">Wendehäuser Straße 14,<br/> 99988 Südeichsfeld <br/>OT Diedorf </p>
                    </Col>
                    <Col xs={6} md={4} className='col'>
                        <p className='heading'><img className="footimg" src={tel} alt="Telefone" /><span> Telefon</span></p>
                        <p className="txt"> 036024 / 5650</p>
                    </Col>
                    <Col xs={12} md={4} className='col'>
                        <p className='heading'><img className="footimg" src={mail} alt="Telefone" /><span> E-Mail</span></p>
                        <p className="txt"> info@galabau-goldmann.de </p>
                    </Col>
                </Row>
            </footer>
            <div className='w-100' style={{backgroundColor: '#185c27', color: 'white', textAlign: 'center', position: 'relative', zIndex: '15'}}>
                &copy; {new Date().getFullYear()} Stephan Goldmann GmbH <Nav.Link href='/Impressum'>Impressum</Nav.Link>
            </div>
        </>
    );
}

export default Footer;
