// css:
import './../css/au.css';
import './../css/allserv.css'

// images/ videos:

// others:
import { useWindowWidth } from '../../App';
import { ListGroup, Badge, Card, Col, Row } from 'react-bootstrap';
import { React} from 'react';
import { UpperSpace } from './allserv/allserv';


// data for list
const Maschinenpark = [
    {name: 'Bagger verschiedener Größen', count: 4},
    {name: 'Radlader', count: 5},
    {name: 'LKW', count: 4},
    {name: 'Kleintransporter', count: 3},
    {name: 'Aebi TT 240 mit Schlägelmäher und Mulchfunktion', count: 1}
]

// website überuns
function Überuns(){
    const { label } = useWindowWidth();

    return(
        <>
            <UpperSpace />
                <div className={`imgcontainer ${label}`}>
                    
                    {/*<ResponsiveVideo src={VideoFHD} type="video/mp4" />*/}
                </div>
                <div className={`ueberuns ${label}`}>
                    <div className={`centered-content ${label}`}>
                        <h3>Über Uns</h3>
                        <p>
                            Unser Unternehmen, die Stephan Goldmann GmbH, wurde im Februar 1990 in Wendehausen gegründet. <br />
                            Wir beschäftigen derzeit 17 hochqualifizierte und motivierte Mitarbeiter mit spezifischer Ausbildung und langjähriger Erfahrung. 
                            Unser Team besteht aus Diplom-Bauingenieuren, Meistern, Vorarbeitern, Baufacharbeitern, Pflasterern, Maurern, Gärtnern und Maschinenführern. <br />
                            Zusätzlich bilden wir derzeit zwei Auszubildende im Bereich Garten- und Landschaftsbau aus.
                        </p>
                        <h3>Unser Team</h3>
                        <div className={`team-pic ${label}`}>
                        </div>
                        <Row style={{width: '100%', margin:0, padding:0}}>
                            <Col className='w-100'>
                                <p>
                                    Zu unseren Kunden zählen private Bauherren, gewerbliche Unternehmen, kirchliche Bauträger, Wohnungsgenossenschaften sowie öffentliche Einrichtungen, Gemeinden, Stadtverwaltungen und andere. <br />
                                    Dank unserer langjährigen Erfahrung im Garten- und Landschaftsbau, der termingerechten und fachgerechten Ausführung mit hohem Qualitätsniveau 
                                    und der wachsenden Zahl zufriedener Kunden haben wir uns einen anerkannten Ruf im Bereich des Gala-Baus in Thüringen, Osthessen und im Süden Niedersachsens erarbeitet.
                                </p>
                            </Col>
                        </Row>
                        <Row style={{width: '100%', margin:0, padding:0}}>
                            <Col className='w-100'>
                                <Card className={`park ${label}`}>
                                    <Card.Title style={{margin: '15px', color: 'green', textAlign: 'center'}}>Unser Maschinenpark</Card.Title>
                                    <ListGroup as="ol" >
                                        {
                                            Maschinenpark.map((item) =>(
                                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                                    <div className="fw-bold" >
                                                        {item.name}
                                                    </div>
                                                    <Badge bg="success" pill>
                                                        {item.count}
                                                    </Badge>
                                                </ListGroup.Item>
                                            ))
                                        }
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                            <div className="fw-bold">
                                                diverse Kleingeräte: Rüttelplatten, Minitrucks, Fräsen, Agria usw.
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
    );
}

export default Überuns;

/*
const ResponsiveVideo = ({ src, type }) => {
    const [dimensions, setDimensions] = useState({ width: '100vw', height: '100vh' });
  
    useEffect(() => {
      const handleResize = () => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width / height > 16 / 9) {
          // Breiter als 16:9
          setDimensions({ width: 'auto', height: '88vh'});
        } else {
          // Schmaler als 16:9
          setDimensions({ width: '100vw', height: 'auto', 'max-height': '88vh' });
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial call to set dimensions
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return (
      <div className="video-container">
        <video style={dimensions} controls autoPlay>
          <source src={src} type={type} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
};
  */