// base
import { PuzImgGallery, PuzImgTxt, PuzSteckbrief, UpperSpace} from "./allserv";
import img from "./../../img/3bErdarbeitenundEntwässerung/Erdarbeiten/Erdarbeiten_30.JPG"
import './../../css/allserv.css';

// import all images from the folder
const importAllImages = (r) => {
    return r.keys().map(r);
};
const images = importAllImages(require.context("./../../img/3bErdarbeitenundEntwässerung", true, /\.(png|jpe?g|JPG|svg)$/)).flat();

// the text displayed on the right
const txt = 
"Das Erdreich ist der Nährboden aller Pflanzen und der Baugrund für sämtliche Bauten. Er ist die Grundlage für befestigte oder unbefestigte Flächen, Mauern und Ausstattungsgegenstände im Außenbereich.\n Daher sind die ersten Maßnahmen bei der Gestaltung von Freiräumen die Erdarbeiten. \nEin fachgerechter Aushub, einschließlich der entsprechenden Vorarbeiten, sowie die entsprechende Bearbeitung des Bodens sind essentiell für alle folgenden Arbeiten."

// die Aufzählung der Leistungen
const leistungen = [
    "Abbrucharbeiten jeglicher Art und fachgerechte Entsorgung von Altmaterialien (z.B. Betonbruch, Bauschutt, Altholz)",
    "Baumfällung und Wurzelstockrodung",
    "Geländefreimachung, Rodung von Sträuchern, Hecken, Pflanzflächen",
    "Erdarbeiten per Hand oder Maschine, Baugrubenaushub und -verfüllung, Geländemodulation, Ausgleich von Niveauunterschieden",
    "Austausch unbrauchbarer Böden",
    "Kabel- und Rohrverlegung",
    "Trockenlegung von Kellerwänden, Abdichtung, Dämmung",
    "Vorbereitung des Baugrundes, Herstellung des Planums zur Aufnahme der Frostschutz- und Tragschichten für befestigte Flächen",
    "Herstellen von Bodenplatten und Fundamenten (z.B. Fertiggaragen, Gartenhäuser)",
    "Be- und Entwässerungsmaßnahmen (z.B. Regenwasser-Zisternen, Klein-Kläranlagen, Entwässerungsrinnen und -mulden)",
    "Tief- und Straßenbauarbeiten",
    "Böden für die spätere Vegetation (z.B. Rasenflächen, Bepflanzungen) vegetationstechnisch aufarbeiten"
]

// site Erdarbeiten und Entwässerung
export default function EA () {
    return(
        <>
            <UpperSpace />
            <PuzImgTxt 
                heading="Erdarbeiten und Entwässerung"
                img={img}
                text={txt}
            />
            <UpperSpace />
            <PuzSteckbrief leistungen={leistungen}/>
            <UpperSpace />
            <PuzImgGallery imgs={images}/>
        </>
    );
}